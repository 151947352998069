import './apiKeys.scss';
import { Button, SelectBox, TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createApiKey } from '../../api/apiKeys';
import notify from 'devextreme/ui/notify';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import {
	getInitialApiKeysFormData,
	initialApiKeysErrors,
	validate,
} from './apiKeysFormUtils';
import { handleInputChange } from '../users/userFormCommons';
import { ApiKeysPayload } from '../../types/apiKeysResponse';

export default function NewApiKeyForm() {
	const status = ['ACTIVO', 'INACTIVO'];
	const [submitted, setSubmitted] = useState(false);
	const navigate = useNavigate();
	const [formData, setFormData] = useState(getInitialApiKeysFormData({}));
	const [errors, setErrors] = useState(initialApiKeysErrors);

	const onSubmit = async () => {
		setSubmitted(true);
		if (validate(formData)) {
			const payload: ApiKeysPayload = {
				name: formData.name,
				company: [{ code: '' }],
				status: 'ACTIVE',
				apiKey: '',
				additionalInfo: [],
			};
			const result = await createApiKey(payload);
			console.log(result);
			if (result.success) {
				notify(result.message, 'success', defaultShowTimeSToast);
				setTimeout(() => {
					navigate('/apikeys');
				}, 1500);
			} else notify(result.message, 'error', defaultShowTimeSToast);
		}
	};

	useEffect(() => {
		if (submitted) {
			const newErrors = validate(formData);
			setErrors(newErrors);
		}
	}, [submitted, formData]);

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/apikeys'}>API Keys</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Crear Nuevo</span>
			</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<div className="finnetka-card-text-header">
						Configuración básica
					</div>
					<form className="custom-container-form" onSubmit={onSubmit}>
						<TextBox
							value={formData.name}
							onValueChanged={(e) =>
								handleInputChange(setFormData, setErrors)(
									'name',
									e.value
								)
							}
							showClearButton={true}
							label="Nombre"
							labelMode="floating"
							isValid={!errors.name}
						/>
						<TextBox
							value={formData.description}
							onValueChanged={(e) =>
								handleInputChange(setFormData, setErrors)(
									'description',
									e.value
								)
							}
							showClearButton={true}
							label="Descripción"
							labelMode="floating"
							isValid={!errors.description}
						/>
						<SelectBox
							value={'ACTIVO'}
							items={status}
							showClearButton={true}
							label="Estado"
							labelMode="floating"
							isValid={!errors.status}
							disabled={true}
						/>
						<br />
						<div></div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<Button
								text="SIGUIENTE"
								className="finnekta-button-primary-action"
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</React.Fragment>
	);
}
