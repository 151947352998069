import './errors.scss';

export default function Unauthorized() {
    return (
            <div className='error-page complete'>
                <div className="error-message">
                    <h1>401</h1>
                    <h4>Acceso no autorizado</h4>
                    <a href="#/home">Volver al inicio</a>
                </div>
            </div>
    )
}