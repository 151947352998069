import Notify from 'devextreme/ui/notify';
import {
	NotificationType,
	NotificationPosition,
	NotificationDirection,
} from '../types/notification';
import { createRoot } from 'react-dom/client';


const getNotificationDirection = (position: string): NotificationDirection => {
	return position.includes('top') ? 'down-push' : 'up-push';
  };

let activeNotifications: string[] = [];

export const showNotification = (params: {
	message: string;
	type: NotificationType;
	displayTime?: number;
	position: NotificationPosition;
}): void => {
	// TS no soporta valores por defecto para types en un objeto, esto lo simula
	params.displayTime = params.displayTime ?? 6000;
	const direction = getNotificationDirection(params.position);
	const ToastContent = (props: { message: string; type: string }) => {
		return (
			<div className="custom-toast">
				<div className="custom-toast-message">
					<p>{props.message}</p>
				</div>
				<div className="custom-toast-button">
					<span
						className={
							'dx-icon dx-icon-close ' +
							(props.type === 'info' || props.type === 'warning'
								? 'icon-dark'
								: '')
						}
					></span>
				</div>
			</div>
		);
	};

	const toastTemplate = (element: HTMLElement) => {
		const root = createRoot(element); // Create a root for the element
		root.render(
			<ToastContent message={params.message} type={params.type} />
		); // Render the ToastContent component
	};

	if (activeNotifications.includes(params.message)) {
		console.log('Notificación ya se está mostrando:', params.message);
		return;
	}

	activeNotifications.push(params.message);

	Notify(
		{
			message: params.message,
			type: params.type,
			displayTime: params.displayTime,
			// Documentacion de esto =>
			//  https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxToast/Configuration/#contentComponent
			// https://js.devexpress.com/React/Documentation/ApiReference/Common/Object_Structures/animationConfig/#type
			animation: {
				show: {
					type: 'css',
					duration: 400,
					from: 'animate__animated animate__fadeInDown animate__faster',
					to: '',
				},
				hide: {
					type: 'css',
					duration: 500,
					from: '',
					to: 'animate__animated animate__backOutRight animate__faster',
				},
			},
			onHiding: () => {
				activeNotifications = activeNotifications.filter(
					(m) => m !== params.message
				);
			},
			closeOnClick: true,
			closeOnSwipe: true,
			deferRendering: true,
			minHeight: 60,
			hoverStateEnabled: false,
			//documentacion de esto => https://js.devexpress.com/React/Documentation/Guide/UI_Components/Common/Templates/#Custom_Templates
			contentTemplate: toastTemplate,
		},
		{
			position: params.position,
			direction: direction,
		}
	);
};
