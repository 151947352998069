import { AuthValidate } from '../types/authValidateResponse';
import { LoginResponse } from '../types/loginResponse';
import axios from 'axios';
import { getUserHeaders } from './config';

export async function signIn(userValue: string, password: string) {
	try {
		let deviceId = await getDeviceId();
		// Send request
		const response = await axios.post<LoginResponse>(
			'auth',
			{
				deviceId,
				userValue,
				password,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		sessionStorage.setItem(
			'user_access_token',
			response.data.userSession.accessToken
		);

		sessionStorage.setItem(
			'realm_access_token',
			response.data.userRealm.accessToken
		);
		return {
			isOk: true,
			data: {
				email: response.data.userSession.session.userInfo.email,
				userValue: response.data.userSession.session.userValue,
				fullName: response.data.userSession.session.userInfo.fullName,
				avatarUrl:
					'https://media.licdn.com/dms/image/D4D03AQEQ79FY3-nXhQ/profile-displayphoto-shrink_800_800/0/1710549754770?e=1717027200&v=beta&t=wyGERy_LEXGUwxUTGycwiWcCdxNPHuz9gEYd2F_31NQ',
				actions: response.data.userRealm.session.actions,
			},
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message:
				'No se ha podido loguear al sistema, consulte con el administrador',
		};
	}
}

export async function getUser() {
	try {
		// Send request
		const response = await axios.get<AuthValidate>('auth/validate', {
			headers: getUserHeaders(),
		});
		return {
			isOk: true,
			data: {
				email: response.data.userInfo.email,
				userValue: response.data.userValue,
				fullName: response.data.userInfo.fullName,
				avatarUrl:
					'https://media.licdn.com/dms/image/D4D03AQEQ79FY3-nXhQ/profile-displayphoto-shrink_800_800/0/1710549754770?e=1717027200&v=beta&t=wyGERy_LEXGUwxUTGycwiWcCdxNPHuz9gEYd2F_31NQ',

				actions: response.data.actions,
			},
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message:
				'No se ha podido obtener la información del usuario, consulte con el administrador',
		};
	}
}

async function getDeviceId() {
	const key = 'device_id';
	let deviceId: string | null = null;

	const userAgent = navigator.userAgent;
	console.log(userAgent);

	deviceId = localStorage.getItem(key);

	if (deviceId === null) {
		console.log('no existe');
		deviceId = crypto.randomUUID();
		console.log('y generé esto: ' + deviceId);
	}

	await axios
		.post<any>(
			'device',
			{
				deviceId,
				userAgent,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then(() => {
			localStorage.setItem(key, deviceId as string);
			console.log('Se registró correctamente el deviceId');
		})
		.catch((exception: any) => {
			console.error(exception);
			throw exception;
		});

	return deviceId;
}

export async function createAccount(userValue: string, password: string) {
	try {
		// Send request
		console.log(userValue, password);

		return {
			isOk: true,
		};
	} catch {
		return {
			isOk: false,
			message: 'Failed to create account',
		};
	}
}

export async function changePassword(userValue: string, recoveryCode?: string) {
	try {
		// Send request
		console.log(userValue, recoveryCode);

		return {
			isOk: true,
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message: 'Failed to change password',
		};
	}
}

export async function resetPassword(userValue: string) {
	try {
		// Send request
		console.log(userValue);

		return {
			isOk: true,
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message: 'Failed to reset password',
		};
	}
}
