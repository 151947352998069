import { getRealmHeaders, getUserHeaders } from '../../api/config';
import { apiBaseUrl } from '../../config/globalVariables';
import { showNotification } from '../../utils/showNotification';

const uiConfigString = localStorage.getItem('ui_config');

const uiConfig = uiConfigString ? JSON.parse(uiConfigString) : null;

const notificationsPosition = uiConfig ? uiConfig?.notificationsPosition : uiConfig?.DefaultUiConfig?.notificationsPosition;

export const documentsTypesDataSource = {
	store: {
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}documenttypes`,
		beforeSend: function (request: any) {
			request.headers = {
				...getRealmHeaders(),
				...getUserHeaders(),
			};
		},
		errorHandler: function (error: any) {
			console.log(error.httpStatus);
			if (error.httpStatus === 401) {
				showNotification({
					message:
						'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					//dar el valor directo por local storage en vez de UiConfigContext por accesibilidad
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'description'],
};

export const rolesDataSource = {
	store: {
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}roles`,
		beforeSend: function (request: any) {
			request.headers = {
				...getRealmHeaders(),
				...getUserHeaders(),
			};
		},
		errorHandler: function (error: any) {
			console.log(error.httpStatus);
			if (error.httpStatus === 401) {
				showNotification({
					message:
						'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'nombre', 'descripcion', 'tipo', 'estado'],
};

export const getInitialFormData = (userData: any = {}) => {
	const [firstName = '', lastName = ''] = (userData.fullName || '').split(
		' '
	);

	return {
		firstName,
		lastName,
		email: userData.email || '',
		phone: userData.phone || '',
		documentType: userData.documentType || 'CI',
		documentNumber: userData.documentNumber || '',
		userValue: userData.userValue || '',
		roles: userData.roles || [],
	};
};

export const initialErrors = {
	firstName: '',
	lastName: '',
	email: '',
	documentType: '',
	documentNumber: '',
	userValue: '',
	phone: '',
	roles: '',
};

export const handleInputChange =
	(setFormData: Function, setErrors: Function) =>
	(field: any, value: any) => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			[field]: value,
		}));
		setErrors((prevErrors: any) => ({
			...prevErrors,
			[field]: '',
		}));
	};

export const handleClearForm = (setFormData: Function) => () => {
	setFormData(getInitialFormData({}));
};

export const validate = (formData: any) => {
	const newErrors = {} as any;
	if (!formData.firstName) newErrors.firstName = 'El nombre es requerido';
	if (!formData.lastName) newErrors.lastName = 'El apellido es requerido';
	if (!formData.email) {
		newErrors.email = 'El correo electrónico es requerido';
	} else {
		const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
		if (!emailRegex.test(formData.email)) {
			newErrors.email = 'El correo electrónico no es válido';
		}
	}
	if (!formData.phone) newErrors.phone = 'El número de celular es requerido';
	if (!formData.documentType)
		newErrors.documentType = 'El tipo de documento es requerido';
	if (!formData.documentNumber)
		newErrors.documentNumber = 'El número de documento es requerido';
	if (!formData.roles || formData.roles.length === 0) {
		newErrors.roles = 'Debe asignar al menos un rol';
	}
	return newErrors;
};
