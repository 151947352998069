import { Button, SelectBox, TagBox, TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import './users.scss';
import { Link, useNavigate } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import { createUser } from '../../api/users';
import {
	defaultShowTimeSToast,
} from '../../config/globalVariables';
import { ClickEvent } from 'devextreme/ui/button';
import { documentsTypesDataSource, handleClearForm, handleInputChange, initialErrors, getInitialFormData, rolesDataSource, validate } from './userFormCommons';

export default function CreateUserForm() {
	const navigate = useNavigate();
	const [submitted, setSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formData, setFormData] = useState(getInitialFormData({}));
	const [errors, setErrors] = useState(initialErrors);

	// Función para manejar el envío del formulario
	const handleSubmit = async (e: React.FormEvent) => {
		setSubmitted(true);
		if (validate(formData)) {
			setIsSubmitting(true);
			const payload = {
				firstName: formData.firstName,
				lastName: formData.lastName,
				userValue: formData.documentNumber,
				documentType: formData.documentType,
				documentNumber: formData.documentNumber,
				email: formData.email,
				phone: formData.phone,
				roles: formData.roles,
				isActive: true,
			};
			const result = await createUser(payload);
			if (result.success) {
				notify(result.message, 'success', defaultShowTimeSToast);
				setTimeout(() => {
					navigate('/users');
				}, 1500);
			} else {
				notify(result.message, 'error', defaultShowTimeSToast);
				setIsSubmitting(false);
			}
		}
	};

	const handleButtonClick = (e: ClickEvent) => {
		e.event!.preventDefault();
		handleSubmit(e as unknown as React.FormEvent);
	};

	useEffect(() => {
        if (submitted) {
            const newErrors = validate(formData);
            setErrors(newErrors);
        }
    }, [submitted, formData]);
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/users'}>Usuarios</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Crear usuario</span>
			</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<div className="finnetka-card-text-header">
						Configuración básica
					</div>
					<form
						className="custom-container-form"
						onSubmit={handleSubmit}
					>
						<TextBox
							value={formData.firstName}
							onValueChanged={(e) => handleInputChange(setFormData, setErrors)('firstName', e.value)}
							label="Nombre"
							isValid={!errors.firstName}
						/>
						<TextBox
							value={formData.lastName}
							onValueChanged={(e) => handleInputChange(setFormData, setErrors)('lastName', e.value)}
							label="Apellido"
							isValid={!errors.lastName}
						/>
						<SelectBox
							label="Tipo de documento"
							dataSource={documentsTypesDataSource as any}
							valueExpr="id"
							displayExpr="description"
							value={formData.documentType}
							onValueChanged={(e) => handleInputChange(setFormData, setErrors)('documentType', e.value)}
							isValid={!errors.documentType}
						/>
						<TextBox
							value={formData.documentNumber}
							onValueChanged={(e) => handleInputChange(setFormData, setErrors)('documentNumber', e.value)}
							label="Número de documento"
							isValid={!errors.documentNumber}
						/>
						<TextBox
							value={formData.email}
							onValueChanged={(e) => handleInputChange(setFormData, setErrors)('email', e.value)}
							label="Correo electrónico"
							mode="email"
							isValid={!errors.email}
						/>
						<TextBox
							value={formData.phone}
							onValueChanged={(e) => handleInputChange(setFormData, setErrors)('phone', e.value)}
							label="Número de celular"
							mode="tel"
							isValid={!errors.phone}
						/>
						<TagBox
							label="Asignar Roles"
							dataSource={rolesDataSource as any}
							value={formData.roles}
							valueExpr="id"
							displayExpr="descripcion"
							onValueChanged={(e) => handleInputChange(setFormData, setErrors)('roles', e.value)}
							isValid={!errors.roles}
						/>
					<br />
					<div></div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								gap: '0.5rem',
							}}
						>
							<Button
								text="LIMPIAR FORMULARIO"
								className="finnekta-button-secondary-action"
								onClick={handleClearForm(setFormData)}
								/>
							<Button
								text="CREAR USUARIO"
								className="finnekta-button-primary-action "
								onClick={handleButtonClick}
								disabled={isSubmitting}
							/>
						</div>
					</form>
				</div>
			</div>
		</React.Fragment>
	);
}
