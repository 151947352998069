export const getInitialApiKeysFormData = (apiKeyData: any = {}) => {
	' ';
	return {
		name: apiKeyData.name,
		description: apiKeyData.description,
		created: apiKeyData.created,
		status: apiKeyData.status === 1 ? 'ACTIVO' : 'INACTIVO',
	};
};

export const initialApiKeysErrors = {
	name: '',
	description: '',
	created: '',
	status: '',
};

export const handleClearForm = (setFormData: Function) => () => {
	setFormData(getInitialApiKeysFormData({}));
};

export const validate = (formData: any) => {
	const newErrors = {} as any;
	if (!formData.name) newErrors.name = 'El nombre es requerido';
	if (!formData.description)
		newErrors.description = 'La descripción es requerida';
	if (!formData.created)
		newErrors.created = 'La fecha de expiración es requerida';
	if (!formData.status) newErrors.status = 'El estado es requerido';
	return newErrors;
};
