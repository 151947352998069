import './errors.scss';

export default function Forbidden() {
    return (
            <div className='error-page not-complete'>
                <div className="error-message">
                    <h1>403</h1>
                    <h4>Acceso denegado</h4>
                </div>
            </div>
    )
}