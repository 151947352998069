import React from 'react';
import './Footer.scss';

export default function Footer({ ...rest }) {
	return (
		<footer
			className={'footer animate__animated animate__slideInUp'}
			{...rest}
		/>
	);
}
