import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';
import type { UserPanelProps } from '../../types';

export default function UserPanel({ menuMode }: Readonly<UserPanelProps>) {
	const { user, signOut } = useAuth();
	const navigate = useNavigate();

	const navigateToProfile = useCallback(() => {
		navigate('/configuracion');
	}, [navigate]);
	const menuItems = useMemo(
		() => [
			{
				text: 'Configuración',
				icon: 'mdi mdi-cog infinite-rotate',
				onClick: navigateToProfile,
			},
			{
				text: 'Logout',
				icon: 'mdi mdi-logout',
				onClick: signOut,
			},
		],
		[navigateToProfile, signOut]
	);
	return (
		<div className={'user-panel'}>
			<div className={'user-info'}>
				<i className="mdi mdi-account-circle"></i>
				<div className={'user-name'}>{user!.fullName}</div>
			</div>

			{menuMode === 'context' && (
				<ContextMenu
					items={menuItems}
					target={'.user-button'}
					showEvent={'dxclick'}
					width={210}
					cssClass={'user-menu'}
				>
					<Position
						my={{ x: 'center', y: 'top' }}
						at={{ x: 'center', y: 'bottom' }}
					/>
				</ContextMenu>
			)}
			{menuMode === 'list' && (
				<List className={'dx-toolbar-menu-action'} items={menuItems} />
			)}
		</div>
	);
}
