import axios from 'axios';
import { getRealmHeaders, getUserHeaders } from './config';
import { UserPayload } from '../types/userResponse';

export async function createUser(payload: UserPayload) {
	try {
		const response = await axios.post('/users', payload, {
			headers: {
                ...getRealmHeaders(),
                ...getUserHeaders(),
            }
		});
		return {
			success: true,
			message: 'El Usuario fue creado con éxito',
			response: response.data,
		};
	} catch {
		return {
			success: false,
			message: 'El Usuario no pudo ser creado porque pasaron cosas.',
		};
	}
}

export async function editUser(payload: UserPayload) {
	try {
		const response = await axios.put(`/users`, payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return {
			success: true,
			message: 'El Usuario fue editado con éxito',
			response: response.data,
		};
	} catch {
		return {
			success: false,
			message: 'El Usuario no pudo ser editado porque pasaron cosas.',
		};
	}
}

export async function changeStatusUser(userValue: string, statusText: string) {
	try {
		const response = await axios.patch(
			`/users/status/${userValue}`,
			{ status: statusText },
			{
				headers: {
					...getRealmHeaders(),
					...getUserHeaders(),
				},
			}
		);
		return {
			success: true,
			message: 'El Usuario fue editado con éxito',
			response: response.data,
		};
	} catch {
		return {
			success: false,
			message: 'El Usuario no pudo ser editado porque pasaron cosas.',
		};
	}
}
