import React, { useContext, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
	Column,
	Pager,
	Paging,
	FilterRow,
	Lookup,
} from 'devextreme-react/data-grid';
import { UiConfigContext } from '../../contexts/uiConfig';

export default function Task() {
	const { uiConfig } = useContext(UiConfigContext);

	const [filterVisible, setFilterVisible] = useState(uiConfig.dataGrid.defaultFilterVisibility);
	const onToolbarPreparing = (e: any) => {
		e.toolbarOptions.items.unshift(
			{
				location: 'before',
				widget: 'dxTextBox',
				options: {
					mode: 'search',
					placeholder: 'Buscar',
					onEnterKey: (args: any) => {
						e.component.searchByText(
							args.component.option('value')
						);
					},
					elementAttr: {
						class: 'finnekta-textbox-filter',
					},
				},
			},
			{
				location: 'before',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: filterVisible ? 'Ocultar filtros' : 'Mostrar filtros',
					onClick: () => {
						setFilterVisible(!filterVisible);
					},
					elementAttr: {
						class: 'finnkekta-button-filter',
					},
				},
			}
		);
	};

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Tasks</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<DataGrid
						dataSource={dataSource as any}
						showBorders={false}
						focusedRowEnabled={false}
						columnAutoWidth={true}
						columnHidingEnabled={true}
						rowAlternationEnabled={true}
						hoverStateEnabled={true}
						onToolbarPreparing={onToolbarPreparing}
					>
						<Paging
							defaultPageSize={
								uiConfig.dataGrid.defaultRowsPerPage
							}
						/>
						<Pager
							showPageSizeSelector={true}
							showInfo={true}
							showNavigationButtons={true}
						/>
						<FilterRow visible={filterVisible} />
						<Column
							dataField={'Task_ID'}
							width={90}
							hidingPriority={2}
						/>
						<Column
							dataField={'Task_Subject'}
							width={190}
							caption={'Subject'}
							hidingPriority={8}
						/>
						<Column
							dataField={'Task_Status'}
							caption={'Status'}
							hidingPriority={6}
						/>
						<Column
							dataField={'Task_Priority'}
							caption={'Priority'}
							hidingPriority={5}
						>
							<Lookup
								dataSource={priorities}
								valueExpr={'value'}
								displayExpr={'name'}
							/>
						</Column>
						<Column
							dataField={'ResponsibleEmployee.Employee_Full_Name'}
							caption={'Assigned To'}
							allowSorting={false}
							hidingPriority={7}
						/>
						<Column
							dataField={'Task_Start_Date'}
							caption={'Start Date'}
							dataType={'date'}
							hidingPriority={3}
						/>
						<Column
							dataField={'Task_Due_Date'}
							caption={'Due Date'}
							dataType={'date'}
							hidingPriority={4}
						/>
						<Column
							dataField={'Task_Priority'}
							caption={'Priority'}
							name={'Priority'}
							hidingPriority={1}
						/>
						<Column
							dataField={'Task_Completion'}
							caption={'Completion'}
							hidingPriority={0}
						/>
					</DataGrid>
				</div>
			</div>
		</React.Fragment>
	);
}

const dataSource = {
	store: {
		version: 2,
		type: 'odata',
		key: 'Task_ID',
		url: 'https://js.devexpress.com/Demos/DevAV/odata/Tasks',
	},
	expand: 'ResponsibleEmployee',
	select: [
		'Task_ID',
		'Task_Subject',
		'Task_Start_Date',
		'Task_Due_Date',
		'Task_Status',
		'Task_Priority',
		'Task_Completion',
		'ResponsibleEmployee/Employee_Full_Name',
	],
};

const priorities = [
	{ name: 'High', value: 4 },
	{ name: 'Urgent', value: 3 },
	{ name: 'Normal', value: 2 },
	{ name: 'Low', value: 1 },
];
