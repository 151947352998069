import React from 'react';
import { DataGridRoles } from './dataGridRoles';

export default function Roles() {
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Roles</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<DataGridRoles />
				</div>
			</div>
		</React.Fragment>
	);
}
