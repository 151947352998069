import './apiKeys.scss';
import TabPanel from 'devextreme-react/tab-panel';
import { dataSource } from './data';
import { Accordion, Button, CheckBox } from 'devextreme-react';
import { Link } from 'react-router-dom';

type Permission = {
	id: number;
	descripcion: string;
	active: boolean;
};

type Cuenta = {
	id: string;
	title: string;
	permissions: Permission[];
};

type Task = {
	id: number;
	status: string;
	priority: string;
	cuentas: Cuenta[];
};

const ItemRender: React.FC<{ readonly permissions: readonly Permission[] }> = ({
	permissions,
}) => (
	<div className="permission-settings">
		{permissions.map((permission) => (
			<CheckBox
				key={permission.id}
				defaultValue={permission.active}
				text={permission.descripcion}
			/>
		))}
	</div>
);

const TaskItem: React.FC<{ cuentas: Cuenta[] }> = ({ cuentas }) => (
	<div className="permission-accordion-content">
		<Accordion
			dataSource={cuentas}
			deferRendering={true}
			animationDuration={500}
			itemTitleRender={(data: any) => data.title}
			itemRender={({ permissions }: any) => (
				<ItemRender permissions={permissions} />
			)}
		/>
	</div>
);

function TabPanelItem({
	data,
}: {
	readonly data: { readonly tasks: readonly Task[] };
}) {
	const taskItems = data.tasks.map((task) => (
		<TaskItem key={task.id} cuentas={task.cuentas} />
	));
	return <div className="tabpanel-item">{taskItems}</div>;
}

export default function ApiKeysPermissions() {
	return (
		<>
			<h2 className="content-block breadcrumb">
				<Link to="/apikeys">API Keys</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Permisos</span>
			</h2>
			<div className="content-block">
				<div className="dx-card responsive-paddings animate__animated animate__fadeIn">
					<div className="finnetka-card-text-header">Permisos</div>
					<span className="text-header-title">
						Productos disponibles
					</span>
					<div className="tabpanel-demo">
						<div className="widget-container">
							<TabPanel
								width="90%"
								height={400}
								animationEnabled={true}
								swipeEnabled={true}
								dataSource={dataSource}
								tabsPosition={'left'}
								iconPosition={'start'}
								itemComponent={TabPanelItem}
							/>
						</div>
					</div>
					<div className="button-container">
						<Button className="finnekta-button-primary-action">
							CREAR API KEY
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
