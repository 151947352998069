import React, { useCallback, useState } from 'react';
import ODataStore from 'devextreme/data/odata/store';
import DataGrid, {
	Column,
	DataGridTypes,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	SearchPanel,
	FilterRow,
	FilterPanel,
	HeaderFilter,
	Summary,
	GroupItem,
	Export,
	Scrolling,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import discountCell from './discountCell';

const pageSizes = [10, 25, 50, 100];

const dataSourceOptions = {
	store: new ODataStore({
		version: 2,
		url: 'https://js.devexpress.com/Demos/SalesViewer/odata/DaySaleDtoes',
		key: 'Id',
		beforeSend(request) {
			const year = new Date().getFullYear() - 1;
			request.params.startDate = `${year}-05-10`;
			request.params.endDate = `${year}-5-15`;
		},
	}),
};

const onExporting = (e: DataGridTypes.ExportingEvent) => {
	const workbook = new Workbook();
	const worksheet = workbook.addWorksheet('Main sheet');

	exportDataGrid({
		component: e.component,
		worksheet,
		autoFilterEnabled: true,
	}).then(() => {
		workbook.xlsx.writeBuffer().then((buffer) => {
			saveAs(
				new Blob([buffer], { type: 'application/octet-stream' }),
				'DataGrid.xlsx'
			);
		});
	});
};

const App = () => {
	const [collapsed, setCollapsed] = useState(true);

	const onContentReady = useCallback(
		(e: DataGridTypes.ContentReadyEvent) => {
			if (collapsed) {
				e.component.expandRow(['EnviroCare']);
				setCollapsed(false);
			}
		},
		[collapsed]
	);

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Sales</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<DataGrid
						dataSource={dataSourceOptions}
						allowColumnReordering={true}
						rowAlternationEnabled={true}
						focusedRowEnabled={false}
						showBorders={false}
						width="100%"
						columnAutoWidth={true}
						hoverStateEnabled={true}
						onContentReady={onContentReady}
						onExporting={onExporting}
						className="dx-auto-height"
					>
						<Scrolling mode="infinite" />{' '}
						{/* or "virtual" | "infinite" */}
						<GroupPanel visible={true} />
						<FilterRow visible={true} />
						<FilterPanel visible={true} />
						<HeaderFilter visible={true} />
						<SearchPanel
							visible={true}
							highlightCaseSensitive={true}
							width={240}
						/>
						<Grouping autoExpandAll={true} />
						<Column dataField="Product" />
						<Column
							dataField="Amount"
							caption="Sale Amount"
							dataType="number"
							format="₲ ###,###,##0.##"
							alignment="right"
						/>
						<Column
							dataField="Discount"
							caption="Discount %"
							dataType="number"
							format="percent"
							alignment="right"
							allowGrouping={false}
							cellRender={discountCell}
							cssClass="bullet"
						/>
						<Column
							dataField="SaleDate"
							dataType="date"
							format="dd/MM/yyyy"
						/>
						<Column dataField="Region" dataType="string" />
						<Column dataField="Sector" dataType="string" />
						<Column dataField="Channel" dataType="string" />
						<Column dataField="Customer" dataType="string" />
						<Summary>
							<GroupItem column="Product" summaryType="count" />
							<GroupItem
								column="Amount"
								summaryType="sum"
								valueFormat="₲ ###,###,##0.##"
								displayFormat="Total del Grupo: {0}"
							/>
						</Summary>
						<Pager
							allowedPageSizes={pageSizes}
							showPageSizeSelector={true}
							showNavigationButtons={true}
							showInfo={true}
						/>
						<Paging defaultPageSize={10} />
						<Export enabled={true} allowExportSelectedData={true} />
					</DataGrid>
				</div>
			</div>
		</React.Fragment>
	);
};

export default App;
