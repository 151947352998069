import { useContext, useState, useRef, useEffect } from 'react';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { apiBaseUrl } from '../../config/globalVariables';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	FilterRow,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	ColumnFixing,
	ColumnChooser,
	DataGridTypes,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../utils/showNotification';
import { useAuth } from '../../contexts/auth';
import { UiConfigContext } from '../../contexts/uiConfig';
import { getRealmHeaders, getUserHeaders } from '../../api/config';

export const DataGridRoles = () => {
	const navigate = useNavigate();
	const { signOut } = useAuth();
	const { uiConfig } = useContext(UiConfigContext);
	const [filterVisible, setFilterVisible] = useState(uiConfig.dataGrid.defaultFilterVisibility);
	const statusLookup = [
		{ value: 'ACTIVO', text: 'Activo' },
		{ value: 'INACTIVO', text: 'Inactivo' },
	];

	const onToolbarPreparing = (e: any) => {
		e.toolbarOptions.items.unshift(
			{
				location: 'before',
				widget: 'dxTextBox',
				options: {
					mode: 'search',
					placeholder: 'Buscar',
					onEnterKey: (args: any) => {
						e.component.searchByText(
							args.component.option('value')
						);
					},
					elementAttr: {
						class: 'finnekta-textbox-filter',
					},
				},
			},
			{
				location: 'before',
				widget: 'dxButton',
				options: {
					icon: 'filter',

					text: filterVisible ? 'Ocultar filtros' : 'Mostrar filtros',
					onClick: () => {
						setFilterVisible(!filterVisible);
					},
					elementAttr: {
						class: 'finnkekta-button-filter',
					},
				},
			}
		);
	};

	const rolesDataSource = {
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}roles`,
			beforeSend: function (request: any) {
				request.headers = {
					...getRealmHeaders(),
					...getUserHeaders(),
				};
			},
			errorHandler: function (error: any) {
				console.log(error.httpStatus);
				if (error.httpStatus === 401) {
					showNotification({
						message:
							'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
						type: 'error',
						displayTime: 12000,
						position: uiConfig.notificationsPosition,
					});
					signOut();
				}
				if (error.httpStatus === 403) {
					navigate('/forbidden');
				}
			},
		},
		select: [
			'id',
            'nombre',
            'descripcion',
            'tipo',
            'estado',
	    ],
    };
	const pageSizes = useRef([5, 7, 10, 25, 50, 100]);
	const [updatedPageSizes, setUpdatedPageSizes] = useState(pageSizes.current)

	useEffect(() => {
		const defaultRowsPerPage = uiConfig.dataGrid.defaultRowsPerPage;
		if (!pageSizes.current.includes(defaultRowsPerPage)) {
			const newPageSizes = [
				...pageSizes.current,
				defaultRowsPerPage,
			].sort((a, b) => a - b);
			pageSizes.current = newPageSizes;
			setUpdatedPageSizes(newPageSizes);
		}
	}, [uiConfig]);

	const onExporting = (e: DataGridTypes.ExportingEvent) => {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');

		exportDataGrid({
			component: e.component,
			worksheet,
			autoFilterEnabled: true,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], { type: 'application/octet-stream' }),
					'DataGrid.xlsx'
				);
			});
		});
	};
	return (
		<DataGrid
			dataSource={rolesDataSource as any}
			showBorders={false}
			focusedRowEnabled={false}
			columnAutoWidth={true}
			columnHidingEnabled={true}
			rowAlternationEnabled={true}
			hoverStateEnabled={true}
			allowColumnReordering={true}
			allowColumnResizing={true}
			width="100%"
			className="dx-datagrid-filter-top"
			onExporting={onExporting}
			onToolbarPreparing={onToolbarPreparing}
            //keyExpr={'userId'}
		>
			<Scrolling mode="standard" />
			<GroupPanel visible={false} />
			<FilterRow visible={filterVisible} />
			<FilterPanel visible={false} />
			<HeaderFilter visible={false} />
			<ColumnFixing enabled={false} />
			<ColumnChooser enabled={false} />
			<Grouping autoExpandAll={true} />
			<RemoteOperations
				filtering={true}
				paging={true}
				sorting={true}
				summary={false}
				grouping={false}
				groupPaging={false}
			/>
			<Column
				dataField={'id'}
				width={200}
				hidingPriority={1}
				caption={'Id'}
				visible={false}
			/> 
			<Column
				hidingPriority={6}
				dataField={'nombre'}
				caption={'Nombre'}
			/>
			<Column
				hidingPriority={5}
				dataField={'descripcion'}
				caption={'Descripción'}
			/>
			<Column
				hidingPriority={2}
				dataField={'tipo'}
				caption={'Tipo'}
				visible={false}
			/>
			<Column
				hidingPriority={3}
				dataField={'estado'}
				caption={'Estado'}
                lookup={{
					dataSource: statusLookup,
					valueExpr: 'value',
					displayExpr: 'text',
				}}
			/>
			<Pager
				allowedPageSizes={updatedPageSizes}
				showPageSizeSelector={true}
				showNavigationButtons={true}
				showInfo={true}
				displayMode="compact"
			/>
			<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
			<Export enabled={false} />
		</DataGrid>
	);
};
