import React from 'react';
import './users.scss';
import { DataGridUsers } from './dataGridUsers';
import { Button } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
import { getUserPermissions } from '../../config/actions';
import { useAuth } from '../../contexts/auth';

export default function Users() {
	const navigate = useNavigate();
	const { user } = useAuth();
	const userPermissions = getUserPermissions(user);

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Usuarios</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<div
						style={{ display: 'flex', justifyContent: 'flex-end' }}
					>
						<Button
							className="finnekta-datagrid-button-add-item"
							onClick={() => navigate('/users/new')}
							disabled={!userPermissions.canCreateUser}
						>
							<span className="mdi mdi-account-plus"></span>
							{/*
							 */}
							CREAR NUEVO USUARIO
						</Button>
					</div>
					<DataGridUsers />
				</div>
			</div>
		</React.Fragment>
	);
}
