import React from 'react';
import './schemas.scss';
import { SchemaData } from './schemas-data';
import DataGrid, {
	Column,
	Pager,
	Paging,
	FilterRow,
	MasterDetail,
} from 'devextreme-react/data-grid';

import MasterDetailView from './master-detail-view';

const pageSizes = [5, 10, 25, 50, 100, 0];

export default function Schemas() {
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Schemas</h2>

			<div
				className={
					'content-block dx-card responsive-paddings animate__animated animate__fadeIn'
				}
			>
				<DataGrid
					dataSource={SchemaData as any}
					showBorders={false}
					focusedRowEnabled={false}
					columnAutoWidth={true}
					columnHidingEnabled={false}
					rowAlternationEnabled={false}
					hoverStateEnabled={true}
					allowColumnReordering={false}
					allowColumnResizing={true}
					onRowExpanding={(e: any) => {
						// es para tener colapsado 1 sola fila por vez
						e.component.collapseAll(-1);
					}}
				>
					<MasterDetail enabled={true} component={MasterDetailView} />
					<Paging defaultPageSize={10} />
					<Pager
						showPageSizeSelector={true}
						showInfo={true}
						showNavigationButtons={true}
					/>
					<FilterRow visible={true} />
					<Column dataField={'id'} visible={false} />
					<Column
						dataField={'object'}
						width={'40%'}
						caption={'Objeto'}
					/>
					<Column
						dataField={'description'}
						width={'60%'}
						caption={'Descripción'}
					/>
					<Pager
						allowedPageSizes={pageSizes}
						showPageSizeSelector={true}
						showNavigationButtons={true}
						showInfo={true}
						displayMode="compact"
					/>
				</DataGrid>
			</div>
		</React.Fragment>
	);
}
