import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './login-card.scss';

type LoginCardProps = Readonly<React.PropsWithChildren<unknown>>;
export default function LoginCard({ children }: LoginCardProps) {
	return (
		<ScrollView
			height={'100%'}
			width={'100%'}
			className={
				'with-footer login-card animate__animated animate__backInDown '
			}
		>
			<div className={'dx-card content'}>
				<div
					className={'header animate__animated animate__zoomInDown'}
				></div>
				{children}
			</div>
		</ScrollView>
	);
}
