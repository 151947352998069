import { createContext } from 'react';
import { NotificationPosition } from '../types/notification';

export const UiOptions = {
	stylingMode: [
		{ value: 'filled', text: 'Relleno' },
		{ value: 'outlined', text: 'Bordeado' },
		{ value: 'underlined', text: 'Subrayado' },
	],
	labelMode: [
		{ value: 'static', text: 'Fijo' },
		{ value: 'floating', text: 'Flotante' },
		//{ value: 'hidden', text: 'Oculto' },
		{ value: 'outside', text: 'Externo' },
	],
	defaultFilterVisibility: [
		{ value: true, text: 'Mostrar' },
		{ value: false, text: 'Ocultar' },
	],
	notificationsPosition: [
		{ value: 'bottom right', text: 'Abajo a la derecha'}, 
		{ value: 'bottom left', text: 'Abajo a la izquierda'},
		{ value: 'bottom center', text: 'Abajo en el centro'},
		{ value: 'top right', text: 'Arriba a la derecha'},
		{ value: 'top left', text: 'Arriba a la izquierda'},
		{ value: 'top center', text: 'Arriba en el centro'},
	],
	menuStatus: [
		{ value: 1, text: 'Colapsado' },
		{ value: 2, text: 'Extendido'},
	]
} as const;

export type UiConfig = {
	stylingMode: string;
	labelMode: string;
	dataGrid: UiDataGridType;
	notificationsPosition: NotificationPosition;
	menuStatus: number;
};

export type UiDataGridType = { defaultRowsPerPage: number, defaultFilterVisibility: boolean };

interface UiConfigContextType {
	uiConfig: UiConfig;
	setUiConfig: (uiConfig: UiConfig) => void;
}

export const DefaultUiConfig: UiConfig = {
	stylingMode: 'filled',
	labelMode: 'outside',
	dataGrid: {
		defaultRowsPerPage: 5,
		defaultFilterVisibility: true,
	},
	notificationsPosition: 'top right',
	menuStatus: 2,
};

const defaultValue: UiConfigContextType = {
	uiConfig: DefaultUiConfig,
	setUiConfig: (uiConfig: UiConfig) => console.log(JSON.stringify(uiConfig)),
};

export const UiConfigContext = createContext<UiConfigContextType>(defaultValue);
