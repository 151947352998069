export const tasks = [
	{
		id: 1,
		status: 'Cuentas Corrientes',
		priority: 'high',
		cuentas: [
			{
				id: 'bccp',
				title: 'Cuenta Nro.7686868',
				permissions: [
					{ id: 1, descripcion: 'Acceso Full', active: false },
					{ id: 2, descripcion: 'Visualizar', active: true },
					{ id: 3, descripcion: 'Pago de Salarios', active: true },
					{ id: 4, descripcion: 'Pago de Proveedores', active: true },
					{ id: 5, descripcion: 'Pago de Impuestos', active: false },
					{ id: 6, descripcion: 'Transferencias', active: false },
					{ id: 7, descripcion: 'Cobranzas', active: true },
					{ id: 8, descripcion: 'Pagos aduaneros', active: false },
				],
			},
			{
				id: 'bnf',
				title: 'Cuenta Nro.345354',
				permissions: [
					{ id: 1, descripcion: 'Acceso Full', active: false },
					{ id: 2, descripcion: 'Visualizar', active: true },
					{ id: 3, descripcion: 'Pago de Salarios', active: true },
					{ id: 4, descripcion: 'Pago de Proveedores', active: true },
					{ id: 5, descripcion: 'Pago de Impuestos', active: false },
					{ id: 6, descripcion: 'Transferencias', active: false },
					{ id: 7, descripcion: 'Cobranzas', active: true },
					{ id: 8, descripcion: 'Pagos aduaneros', active: false },
				],
			},
			{
				id: 'bfca',
				title: 'Cuenta Nro.959595',
				permissions: [
					{ id: 1, descripcion: 'Acceso Full', active: false },
					{ id: 2, descripcion: 'Visualizar', active: true },
					{ id: 3, descripcion: 'Pago de Salarios', active: true },
					{ id: 4, descripcion: 'Pago de Proveedores', active: true },
					{ id: 5, descripcion: 'Pago de Impuestos', active: false },
					{ id: 6, descripcion: 'Transferencias', active: false },
					{ id: 7, descripcion: 'Cobranzas', active: true },
					{ id: 8, descripcion: 'Pagos aduaneros', active: false },
				],
			},
		],
	},
];

export const dataSource = [
	{
		icon: 'mdi mdi-cash-multiple',
		title: 'Cuentas Corrientes',
		tasks: tasks.filter((item) => item.status === 'Cuentas Corrientes'),
	},
	{
		icon: 'mdi mdi-piggy-bank',
		title: 'Cuentas de Ahorro',
		tasks: tasks.filter((item) => item.status === 'Cuentas de Ahorro'),
		disabled: true,
	},
	{
		icon: 'mdi mdi-hand-coin',
		title: 'Préstamos',
		tasks: tasks.filter((item) => item.status === 'Préstamos'),
		disabled: true,
	},
	{
		icon: 'mdi mdi-invoice-text-clock',
		title: 'CDAs',
		tasks: tasks.filter((item) => item.status === 'CADs'),
		disabled: true,
	},
	{
		icon: 'mdi mdi-credit-card',
		title: 'Tarjetas de Crédito',
		tasks: tasks.filter((item) => item.status === 'Tarjetas de Crédito'),
		disabled: true,
	},
	{
		icon: 'mdi mdi-shield-account-variant',
		title: 'Seguros',
		tasks: tasks.filter((item) => item.status === 'Seguros'),
		disabled: true,
	},
];
