export const SchemaData = [
	{
		id: 1,
		object: 'Account dfsdf',
		description: 'Account details',
		example: {
			resourceId: '3dc3d5b3-7023-4848-9853-f5400a64e80f',
			iban: 'DE2310010010123456789',
			currency: 'EUR',
			product: 'SAVINGS A/C',
			status: 'enabled',
			bic: 'AEBAGRAAXXX',
			usage: 'PRIV',
			details: 'GEORGE GEORGIDIS PAYROL',
			balances: [
				{
					balanceAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					balanceType: 'ITAV',
				},
			],
			openedOn: '2017-10-25',
			unutilizedLimit: {
				currency: 'EUR',
				amount: '5768.2',
			},
			accruedDebitInterest: {
				currency: 'EUR',
				amount: '5768.2',
			},
			accruedCreditInterest: {
				currency: 'EUR',
				amount: '5768.2',
			},
			taxOnCreditInterest: {
				currency: 'EUR',
				amount: '5768.2',
			},
			blockedAmount: {
				currency: 'EUR',
				amount: '5768.2',
			},
			cardBlockedTransactions: {
				currency: 'EUR',
				amount: '5768.2',
			},
			availableForAbroad: {
				currency: 'EUR',
				amount: '5768.2',
			},
			_links: {
				balances: {
					href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
				},
				transactions: {
					href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
				},
			},
		},
		schema: {
			type: 'object sdfsdf',
			required: ['currency', 'iban'],
			properties: {
				resourceId: {
					type: 'string',
					example: '3dc3d5b3-7023-4848-9853-f5400a64e80f',
					description:
						'This data element is to be used in the path, when retrieving data from a dedicated account.   This shall be filled, if addressable resource are created by the ASPSP on the /accounts endpoint.  The resourceId is constant at least throughout the lifecycle of a given consent.',
				},
				iban: {
					type: 'string',
					example: 'DE2310010010123456789',
					description: 'IBAN',
					maximum: 35,
				},
				currency: {
					type: 'string',
					example: 'EUR',
					description:
						'Account currency. ISO 4217 Alpha 3 currency code.',
				},
				product: {
					type: 'string',
					description:
						'Product Name of the Bank for this account, proprietary definition (Category Description)',
					example: 'SAVINGS A/C',
					maximum: 35,
				},
				status: {
					type: 'string',
					description:
						'Account status. The value is one of the following: -"enabled": account is available -"deleted": account is terminated -"blocked": account is blocked e.g. for legal reasons',
					example: 'enabled',
				},
				bic: {
					type: 'string',
					description: 'The BIC associated to the account.',
					example: 'AEBAGRAAXXX',
				},
				usage: {
					type: 'string',
					description:
						'Specifies the usage of the account  - PRIV: private personal account  - ORGA: professional account',
					example: 'PRIV',
					maximum: 4,
				},
				details: {
					type: 'string',
					description:
						'Specifications that might be provided by the ASPSP  - characteristics of the account  - characteristics of the relevant card',
					example: 'GEORGE GEORGIDIS PAYROL',
					maximum: 140,
				},
				balances: {
					type: 'array',
					description: 'Array of Balances',
					items: {
						type: 'object',
						required: ['balanceAmount', 'balanceType'],
						properties: {
							balanceAmount: {
								description:
									'Amount and currency for balance for',
								type: 'object',
								required: ['currency', 'amount'],
								properties: {
									currency: {
										type: 'string',
										example: 'EUR',
										description:
											'ISO 4217 Alpha 3 currency code',
									},
									amount: {
										type: 'string',
										example: '5768.2',
										description:
											'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
									},
								},
							},
							balanceType: {
								type: 'string',
								description:
									'closingBooked (CLBD) Balance of the account at the end of the pre-agreed account reporting period. It is the sum of the opening booked balance at the beginning of the period and all entries booked to the account during the pre-agreed account reporting period.    expected (XPCD) Balance composed of booked entries and pending items known at the time of calculation, which projects the end of day balance if everything is booked on the account and no other entry is posted.    openingBooked (OPBD) Book balance of the account at the beginning of the account reporting period. It always equals the closing book balance from the previous report.  interimAvailable (ITAV) Available balance calculated in the course of the account ’servicer’s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified.  interimBooked (ITBD) Balance calculated in the course of the account servicer\'s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified."    forwardAvailable (FWAV) Forward available balance of money that is at the disposal of the account owner on the date specified.  nonInvoiced  Only for card accounts.',
								example: 'ITAV',
							},
						},
					},
				},
				openedOn: {
					type: 'string',
					description:
						'Account opening date  Mandatory for GetAccountDetails and Optional for GetAccountList',
					format: 'date',
					example: '2017-10-25',
				},
				unutilizedLimit: {
					description:
						'Not utilized portion of Overdraft Limit if any is linked with the account  Mandatory for GetAccountDetails and Optional for GetAccountList',
					type: 'object',
					required: {
						$ref: '#/properties/balances/items/properties/balanceAmount/required',
					},
					properties: {
						$ref: '#/properties/balances/items/properties/balanceAmount/properties',
					},
				},
				accruedDebitInterest: {
					description:
						'Accrued Debit Interest  Mandatory for GetAccountDetails and Optional for GetAccountList',
					type: 'object',
					required: {
						$ref: '#/properties/balances/items/properties/balanceAmount/required',
					},
					properties: {
						$ref: '#/properties/balances/items/properties/balanceAmount/properties',
					},
				},
				accruedCreditInterest: {
					description:
						'Accrued Credit Interest  Mandatory for GetAccountDetails and Optional for GetAccountList',
					type: 'object',
					required: {
						$ref: '#/properties/balances/items/properties/balanceAmount/required',
					},
					properties: {
						$ref: '#/properties/balances/items/properties/balanceAmount/properties',
					},
				},
				taxOnCreditInterest: {
					description:
						'Tax on Accrued Credit Interest  Mandatory for GetAccountDetails and Optional for GetAccountList',
					type: 'object',
					required: {
						$ref: '#/properties/balances/items/properties/balanceAmount/required',
					},
					properties: {
						$ref: '#/properties/balances/items/properties/balanceAmount/properties',
					},
				},
				blockedAmount: {
					description:
						'Blockages to the balance of the account due to restrictions imposed by the Bank or other Authorities  Mandatory for GetAccountDetails and Optional for GetAccountList',
					type: 'object',
					required: {
						$ref: '#/properties/balances/items/properties/balanceAmount/required',
					},
					properties: {
						$ref: '#/properties/balances/items/properties/balanceAmount/properties',
					},
				},
				cardBlockedTransactions: {
					description:
						'Blockages to the balance of the account due to uncleared Debit Card Purchases  Mandatory for GetAccountDetails and Optional for GetAccountList',
					type: 'object',
					required: {
						$ref: '#/properties/balances/items/properties/balanceAmount/required',
					},
					properties: {
						$ref: '#/properties/balances/items/properties/balanceAmount/properties',
					},
				},
				availableForAbroad: {
					description:
						'Availability of Funds Transfer Abroad according to current Capital Controls regulations  Mandatory for GetAccountDetails and Optional for GetAccountList',
					type: 'object',
					required: {
						$ref: '#/properties/balances/items/properties/balanceAmount/required',
					},
					properties: {
						$ref: '#/properties/balances/items/properties/balanceAmount/properties',
					},
				},
				_links: {
					properties: {
						balances: {
							properties: {
								href: {
									type: 'string',
									example:
										'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
								},
							},
							additionalProperties: false,
						},
						transactions: {
							$ref: '#/properties/_links/properties/balances',
						},
					},
					additionalProperties: false,
				},
			},
			description: 'Account details',
		},
	},
	{
		id: 2,
		object: 'Account links',
		description: null,
		example: {
			balances: {
				href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
			},
			transactions: {
				href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
			},
		},
		schema: {
			properties: {
				balances: {
					properties: {
						href: {
							type: 'string',
							example:
								'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
						},
					},
					additionalProperties: false,
				},
				transactions: {
					$ref: '#/properties/balances',
				},
			},
			additionalProperties: false,
		},
	},
	{
		id: 3,
		object: 'Account List',
		description: null,
		example: {
			resourceId: '010005251023',
			iban: 'GR2005610010000010005251023',
			currency: 'EUR',
			product: 'SAVINGS A/C',
			status: 'enabled',
			bic: 'AEBAGRAAXXX',
			usage: 'PRIV',
			details: 'FAST TRAVEL SHIPPING SA',
			balances: [
				{
					balanceAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					balanceType: 'ITAV',
				},
			],
			_links: {
				balances: {
					href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
				},
				transactions: {
					href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
				},
			},
		},
		schema: {
			properties: {
				resourceId: {
					type: 'string',
					example: '010005251023',
					description:
						'This data element is to be used in the path, when retrieving data from a dedicated account.   This shall be filled, if addressable resource are created by the ASPSP on the /accounts endpoint.  The resourceId is constant at least throughout the lifecycle of a given consent.',
				},
				iban: {
					type: 'string',
					example: 'GR2005610010000010005251023',
					description: 'IBAN',
					maximum: 35,
				},
				currency: {
					type: 'string',
					example: 'EUR',
					description:
						'Account currency. ISO 4217 Alpha 3 currency code.',
				},
				product: {
					type: 'string',
					example: 'SAVINGS A/C',
					description:
						'Product Name of the Bank for this account, proprietary definition (Category Description)',
					maximum: 35,
				},
				status: {
					type: 'string',
					example: 'enabled',
					description:
						'Account status. The value is one of the following: -"enabled": account is available -"deleted": account is terminated -"blocked": account is blocked e.g. for legal reasons  *Berlin group does not allow changes in list of values. So, AB status values should be mapped to berlin statuses.',
				},
				bic: {
					type: 'string',
					example: 'AEBAGRAAXXX',
					description: 'The BIC associated to the account.',
				},
				usage: {
					type: 'string',
					example: 'PRIV',
					description:
						'Specifies the usage of the account  - PRIV: private personal account  - ORGA: professional account.',
					maximum: 4,
				},
				details: {
					type: 'string',
					example: 'FAST TRAVEL SHIPPING SA',
					description:
						'Specifications that might be provided by the ASPSP  - characteristics of the account  - characteristics of the relevant card',
					maximum: 140,
				},
				balances: {
					type: 'array',
					description: 'It depends on withBalances parameter.',
					items: {
						type: 'object',
						required: ['balanceAmount', 'balanceType'],
						properties: {
							balanceAmount: {
								description:
									'Amount and currency for balance for',
								type: 'object',
								required: ['currency', 'amount'],
								properties: {
									currency: {
										type: 'string',
										example: 'EUR',
										description:
											'ISO 4217 Alpha 3 currency code',
									},
									amount: {
										type: 'string',
										example: '5768.2',
										description:
											'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
									},
								},
							},
							balanceType: {
								type: 'string',
								description:
									'closingBooked (CLBD) Balance of the account at the end of the pre-agreed account reporting period. It is the sum of the opening booked balance at the beginning of the period and all entries booked to the account during the pre-agreed account reporting period.    expected (XPCD) Balance composed of booked entries and pending items known at the time of calculation, which projects the end of day balance if everything is booked on the account and no other entry is posted.    openingBooked (OPBD) Book balance of the account at the beginning of the account reporting period. It always equals the closing book balance from the previous report.  interimAvailable (ITAV) Available balance calculated in the course of the account ’servicer’s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified.  interimBooked (ITBD) Balance calculated in the course of the account servicer\'s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified."    forwardAvailable (FWAV) Forward available balance of money that is at the disposal of the account owner on the date specified.  nonInvoiced  Only for card accounts.',
								example: 'ITAV',
							},
						},
					},
				},
				_links: {
					properties: {
						balances: {
							properties: {
								href: {
									type: 'string',
									example:
										'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
								},
							},
							additionalProperties: false,
						},
						transactions: {
							$ref: '#/properties/_links/properties/balances',
						},
					},
					additionalProperties: false,
					description:
						'Links to the account, which can be directly used for retrieving account information from this dedicated account. Links to "balances" and/or "transactions" These links are only supported, when the corresponding consent has been already granted.',
				},
			},
			additionalProperties: false,
			required: ['iban', 'currency'],
		},
	},
	{
		id: 4,
		object: 'AccountAmount',
		description: null,
		example: {
			currency: 'EUR',
			amount: '5768.2',
		},
		schema: {
			type: 'object',
			required: ['currency', 'amount'],
			properties: {
				currency: {
					type: 'string',
					example: 'EUR',
					description: 'ISO 4217 Alpha 3 currency code',
				},
				amount: {
					type: 'string',
					example: '5768.2',
					description:
						'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
				},
			},
		},
	},
	{
		id: 5,
		object: 'AccountBalance',
		description: null,
		example: {
			balanceAmount: {
				currency: 'EUR',
				amount: '5768.2',
			},
			balanceType: 'ITAV',
		},
		schema: {
			type: 'object',
			required: ['balanceAmount', 'balanceType'],
			properties: {
				balanceAmount: {
					description: 'Amount and currency for balance for',
					type: 'object',
					required: ['currency', 'amount'],
					properties: {
						currency: {
							type: 'string',
							example: 'EUR',
							description: 'ISO 4217 Alpha 3 currency code',
						},
						amount: {
							type: 'string',
							example: '5768.2',
							description:
								'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
						},
					},
				},
				balanceType: {
					type: 'string',
					description:
						'closingBooked (CLBD) Balance of the account at the end of the pre-agreed account reporting period. It is the sum of the opening booked balance at the beginning of the period and all entries booked to the account during the pre-agreed account reporting period.    expected (XPCD) Balance composed of booked entries and pending items known at the time of calculation, which projects the end of day balance if everything is booked on the account and no other entry is posted.    openingBooked (OPBD) Book balance of the account at the beginning of the account reporting period. It always equals the closing book balance from the previous report.  interimAvailable (ITAV) Available balance calculated in the course of the account ’servicer’s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified.  interimBooked (ITBD) Balance calculated in the course of the account servicer\'s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified."    forwardAvailable (FWAV) Forward available balance of money that is at the disposal of the account owner on the date specified.  nonInvoiced  Only for card accounts.',
					example: 'ITAV',
				},
			},
		},
	},
	{
		id: 6,
		object: 'AccountBalanceResponse',
		description: 'Response for /v1/accounts/{account-id}/balances request',
		example: {
			account: {
				iban: 'GR2005610010000010005251023',
				currency: 'EUR',
			},
			balances: [
				{
					balanceAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					balanceType: 'ITAV',
				},
			],
		},
		schema: {
			type: 'object',
			properties: {
				account: {
					description:
						'Identifier of the addressed account. (IBAN + CCY)  Remark for Future: It is recommended to use this data element. The condition might change to "mandatory" in a next version of the specification.',
					type: 'object',
					properties: {
						iban: {
							type: 'string',
							example: 'GR2005610010000010005251023',
							description: 'IBAN',
							maximum: 35,
						},
						currency: {
							type: 'string',
							example: 'EUR',
							description: 'ISO 4217 Alpha 3 currency code',
						},
					},
				},
				balances: {
					type: 'array',
					items: {
						type: 'object',
						required: ['balanceAmount', 'balanceType'],
						properties: {
							balanceAmount: {
								description:
									'Amount and currency for balance for',
								type: 'object',
								required: ['currency', 'amount'],
								properties: {
									currency: {
										type: 'string',
										example: 'EUR',
										description:
											'ISO 4217 Alpha 3 currency code',
									},
									amount: {
										type: 'string',
										example: '5768.2',
										description:
											'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
									},
								},
							},
							balanceType: {
								type: 'string',
								description:
									'closingBooked (CLBD) Balance of the account at the end of the pre-agreed account reporting period. It is the sum of the opening booked balance at the beginning of the period and all entries booked to the account during the pre-agreed account reporting period.    expected (XPCD) Balance composed of booked entries and pending items known at the time of calculation, which projects the end of day balance if everything is booked on the account and no other entry is posted.    openingBooked (OPBD) Book balance of the account at the beginning of the account reporting period. It always equals the closing book balance from the previous report.  interimAvailable (ITAV) Available balance calculated in the course of the account ’servicer’s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified.  interimBooked (ITBD) Balance calculated in the course of the account servicer\'s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified."    forwardAvailable (FWAV) Forward available balance of money that is at the disposal of the account owner on the date specified.  nonInvoiced  Only for card accounts.',
								example: 'ITAV',
							},
						},
					},
					description:
						'A list of balances regarding this account, e.g. the current balance, the last booked balance',
				},
			},
			description:
				'Response for /v1/accounts/{account-id}/balances request',
		},
	},
	{
		id: 7,
		object: 'AccountListResponse',
		description: 'Response for /v1/accounts request',
		example: {
			accounts: [
				{
					resourceId: '010005251023',
					iban: 'GR2005610010000010005251023',
					currency: 'EUR',
					product: 'SAVINGS A/C',
					status: 'enabled',
					bic: 'AEBAGRAAXXX',
					usage: 'PRIV',
					details: 'FAST TRAVEL SHIPPING SA',
					balances: [
						{
							balanceAmount: {
								currency: 'EUR',
								amount: '5768.2',
							},
							balanceType: 'ITAV',
						},
					],
					_links: {
						balances: {
							href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
						},
						transactions: {
							href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
						},
					},
				},
			],
		},
		schema: {
			type: 'object',
			properties: {
				accounts: {
					type: 'array',
					items: {
						properties: {
							resourceId: {
								type: 'string',
								example: '010005251023',
								description:
									'This data element is to be used in the path, when retrieving data from a dedicated account.   This shall be filled, if addressable resource are created by the ASPSP on the /accounts endpoint.  The resourceId is constant at least throughout the lifecycle of a given consent.',
							},
							iban: {
								type: 'string',
								example: 'GR2005610010000010005251023',
								description: 'IBAN',
								maximum: 35,
							},
							currency: {
								type: 'string',
								example: 'EUR',
								description:
									'Account currency. ISO 4217 Alpha 3 currency code.',
							},
							product: {
								type: 'string',
								example: 'SAVINGS A/C',
								description:
									'Product Name of the Bank for this account, proprietary definition (Category Description)',
								maximum: 35,
							},
							status: {
								type: 'string',
								example: 'enabled',
								description:
									'Account status. The value is one of the following: -"enabled": account is available -"deleted": account is terminated -"blocked": account is blocked e.g. for legal reasons  *Berlin group does not allow changes in list of values. So, AB status values should be mapped to berlin statuses.',
							},
							bic: {
								type: 'string',
								example: 'AEBAGRAAXXX',
								description:
									'The BIC associated to the account.',
							},
							usage: {
								type: 'string',
								example: 'PRIV',
								description:
									'Specifies the usage of the account  - PRIV: private personal account  - ORGA: professional account.',
								maximum: 4,
							},
							details: {
								type: 'string',
								example: 'FAST TRAVEL SHIPPING SA',
								description:
									'Specifications that might be provided by the ASPSP  - characteristics of the account  - characteristics of the relevant card',
								maximum: 140,
							},
							balances: {
								type: 'array',
								description:
									'It depends on withBalances parameter.',
								items: {
									type: 'object',
									required: ['balanceAmount', 'balanceType'],
									properties: {
										balanceAmount: {
											description:
												'Amount and currency for balance for',
											type: 'object',
											required: ['currency', 'amount'],
											properties: {
												currency: {
													type: 'string',
													example: 'EUR',
													description:
														'ISO 4217 Alpha 3 currency code',
												},
												amount: {
													type: 'string',
													example: '5768.2',
													description:
														'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
												},
											},
										},
										balanceType: {
											type: 'string',
											description:
												'closingBooked (CLBD) Balance of the account at the end of the pre-agreed account reporting period. It is the sum of the opening booked balance at the beginning of the period and all entries booked to the account during the pre-agreed account reporting period.    expected (XPCD) Balance composed of booked entries and pending items known at the time of calculation, which projects the end of day balance if everything is booked on the account and no other entry is posted.    openingBooked (OPBD) Book balance of the account at the beginning of the account reporting period. It always equals the closing book balance from the previous report.  interimAvailable (ITAV) Available balance calculated in the course of the account ’servicer’s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified.  interimBooked (ITBD) Balance calculated in the course of the account servicer\'s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified."    forwardAvailable (FWAV) Forward available balance of money that is at the disposal of the account owner on the date specified.  nonInvoiced  Only for card accounts.',
											example: 'ITAV',
										},
									},
								},
							},
							_links: {
								properties: {
									balances: {
										properties: {
											href: {
												type: 'string',
												example:
													'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
											},
										},
										additionalProperties: false,
									},
									transactions: {
										$ref: '#/properties/accounts/items/properties/_links/properties/balances',
									},
								},
								additionalProperties: false,
								description:
									'Links to the account, which can be directly used for retrieving account information from this dedicated account. Links to "balances" and/or "transactions" These links are only supported, when the corresponding consent has been already granted.',
							},
						},
						additionalProperties: false,
						required: ['iban', 'currency'],
					},
				},
			},
			description: 'Response for /v1/accounts request',
		},
	},
	{
		id: 8,
		object: 'AccountReference',
		description: null,
		example: {
			iban: 'GR2005610010000010005251023',
			currency: 'EUR',
		},
		schema: {
			type: 'object',
			properties: {
				iban: {
					type: 'string',
					example: 'GR2005610010000010005251023',
					description: 'IBAN',
					maximum: 35,
				},
				currency: {
					type: 'string',
					example: 'EUR',
					description: 'ISO 4217 Alpha 3 currency code',
				},
			},
		},
	},
	{
		id: 9,
		object: 'AccountResponse',
		description: 'Response for /v1/accounts/{account-id} request',
		example: {
			account: {
				resourceId: '3dc3d5b3-7023-4848-9853-f5400a64e80f',
				iban: 'DE2310010010123456789',
				currency: 'EUR',
				product: 'SAVINGS A/C',
				status: 'enabled',
				bic: 'AEBAGRAAXXX',
				usage: 'PRIV',
				details: 'GEORGE GEORGIDIS PAYROL',
				balances: [
					{
						balanceAmount: {
							currency: 'EUR',
							amount: '5768.2',
						},
						balanceType: 'ITAV',
					},
				],
				openedOn: '2017-10-25',
				unutilizedLimit: {
					currency: 'EUR',
					amount: '5768.2',
				},
				accruedDebitInterest: {
					currency: 'EUR',
					amount: '5768.2',
				},
				accruedCreditInterest: {
					currency: 'EUR',
					amount: '5768.2',
				},
				taxOnCreditInterest: {
					currency: 'EUR',
					amount: '5768.2',
				},
				blockedAmount: {
					currency: 'EUR',
					amount: '5768.2',
				},
				cardBlockedTransactions: {
					currency: 'EUR',
					amount: '5768.2',
				},
				availableForAbroad: {
					currency: 'EUR',
					amount: '5768.2',
				},
				_links: {
					balances: {
						href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
					},
					transactions: {
						href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
					},
				},
			},
		},
		schema: {
			type: 'object',
			required: ['account'],
			properties: {
				account: {
					description: 'account',
					type: 'object',
					required: ['currency', 'iban'],
					properties: {
						resourceId: {
							type: 'string',
							example: '3dc3d5b3-7023-4848-9853-f5400a64e80f',
							description:
								'This data element is to be used in the path, when retrieving data from a dedicated account.   This shall be filled, if addressable resource are created by the ASPSP on the /accounts endpoint.  The resourceId is constant at least throughout the lifecycle of a given consent.',
						},
						iban: {
							type: 'string',
							example: 'DE2310010010123456789',
							description: 'IBAN',
							maximum: 35,
						},
						currency: {
							type: 'string',
							example: 'EUR',
							description:
								'Account currency. ISO 4217 Alpha 3 currency code.',
						},
						product: {
							type: 'string',
							description:
								'Product Name of the Bank for this account, proprietary definition (Category Description)',
							example: 'SAVINGS A/C',
							maximum: 35,
						},
						status: {
							type: 'string',
							description:
								'Account status. The value is one of the following: -"enabled": account is available -"deleted": account is terminated -"blocked": account is blocked e.g. for legal reasons',
							example: 'enabled',
						},
						bic: {
							type: 'string',
							description: 'The BIC associated to the account.',
							example: 'AEBAGRAAXXX',
						},
						usage: {
							type: 'string',
							description:
								'Specifies the usage of the account  - PRIV: private personal account  - ORGA: professional account',
							example: 'PRIV',
							maximum: 4,
						},
						details: {
							type: 'string',
							description:
								'Specifications that might be provided by the ASPSP  - characteristics of the account  - characteristics of the relevant card',
							example: 'GEORGE GEORGIDIS PAYROL',
							maximum: 140,
						},
						balances: {
							type: 'array',
							description: 'Array of Balances',
							items: {
								type: 'object',
								required: ['balanceAmount', 'balanceType'],
								properties: {
									balanceAmount: {
										description:
											'Amount and currency for balance for',
										type: 'object',
										required: ['currency', 'amount'],
										properties: {
											currency: {
												type: 'string',
												example: 'EUR',
												description:
													'ISO 4217 Alpha 3 currency code',
											},
											amount: {
												type: 'string',
												example: '5768.2',
												description:
													'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
											},
										},
									},
									balanceType: {
										type: 'string',
										description:
											'closingBooked (CLBD) Balance of the account at the end of the pre-agreed account reporting period. It is the sum of the opening booked balance at the beginning of the period and all entries booked to the account during the pre-agreed account reporting period.    expected (XPCD) Balance composed of booked entries and pending items known at the time of calculation, which projects the end of day balance if everything is booked on the account and no other entry is posted.    openingBooked (OPBD) Book balance of the account at the beginning of the account reporting period. It always equals the closing book balance from the previous report.  interimAvailable (ITAV) Available balance calculated in the course of the account ’servicer’s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified.  interimBooked (ITBD) Balance calculated in the course of the account servicer\'s business day, at the time specified, and subject to further changes during the business day. The interim balance is calculated on the basis of booked credit and debit items during the calculation time/period specified."    forwardAvailable (FWAV) Forward available balance of money that is at the disposal of the account owner on the date specified.  nonInvoiced  Only for card accounts.',
										example: 'ITAV',
									},
								},
							},
						},
						openedOn: {
							type: 'string',
							description:
								'Account opening date  Mandatory for GetAccountDetails and Optional for GetAccountList',
							format: 'date',
							example: '2017-10-25',
						},
						unutilizedLimit: {
							description:
								'Not utilized portion of Overdraft Limit if any is linked with the account  Mandatory for GetAccountDetails and Optional for GetAccountList',
							type: 'object',
							required: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/required',
							},
							properties: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/properties',
							},
						},
						accruedDebitInterest: {
							description:
								'Accrued Debit Interest  Mandatory for GetAccountDetails and Optional for GetAccountList',
							type: 'object',
							required: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/required',
							},
							properties: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/properties',
							},
						},
						accruedCreditInterest: {
							description:
								'Accrued Credit Interest  Mandatory for GetAccountDetails and Optional for GetAccountList',
							type: 'object',
							required: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/required',
							},
							properties: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/properties',
							},
						},
						taxOnCreditInterest: {
							description:
								'Tax on Accrued Credit Interest  Mandatory for GetAccountDetails and Optional for GetAccountList',
							type: 'object',
							required: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/required',
							},
							properties: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/properties',
							},
						},
						blockedAmount: {
							description:
								'Blockages to the balance of the account due to restrictions imposed by the Bank or other Authorities  Mandatory for GetAccountDetails and Optional for GetAccountList',
							type: 'object',
							required: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/required',
							},
							properties: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/properties',
							},
						},
						cardBlockedTransactions: {
							description:
								'Blockages to the balance of the account due to uncleared Debit Card Purchases  Mandatory for GetAccountDetails and Optional for GetAccountList',
							type: 'object',
							required: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/required',
							},
							properties: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/properties',
							},
						},
						availableForAbroad: {
							description:
								'Availability of Funds Transfer Abroad according to current Capital Controls regulations  Mandatory for GetAccountDetails and Optional for GetAccountList',
							type: 'object',
							required: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/required',
							},
							properties: {
								$ref: '#/properties/account/properties/balances/items/properties/balanceAmount/properties',
							},
						},
						_links: {
							properties: {
								balances: {
									properties: {
										href: {
											type: 'string',
											example:
												'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
										},
									},
									additionalProperties: false,
								},
								transactions: {
									$ref: '#/properties/account/properties/_links/properties/balances',
								},
							},
							additionalProperties: false,
						},
					},
				},
			},
			description: 'Response for /v1/accounts/{account-id} request',
		},
	},
	{
		id: 10,
		object: 'AccountTransaction',
		description: null,
		example: {
			entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
			checkId: '22',
			bookingDate: '2017-10-25',
			valueDate: '2017-10-26',
			transactionAmount: {
				currency: 'EUR',
				amount: '5768.2',
			},
			currencyExchange: [
				{
					sourceCurrency: 'RSD',
					exchangeRate: 'sodiacutebsuuho',
					unitCurrency: 'PYG',
					targetCurrency: 'IRR',
					quotationDate: '2017-10-25',
					contractIdentification: '1494470293979136',
				},
				{
					sourceCurrency: 'NZD',
					exchangeRate: 'tocujhur',
					unitCurrency: 'PLN',
					targetCurrency: 'VND',
					quotationDate: '2017-10-25',
					contractIdentification: '3218368987398144',
				},
				{
					sourceCurrency: 'TND',
					exchangeRate: 'fuegefibi',
					unitCurrency: 'ZAR',
					targetCurrency: 'KRW',
					quotationDate: '2017-10-25',
					contractIdentification: '4991039725109248',
				},
			],
			creditorName: 'VASILIS GEORGIADIS',
			creditorAccount: {
				iban: 'GR2005610010000010005251023',
				currency: 'EUR',
			},
			debtorName: 'SILVER AGENT SA',
			debtorAccount: {
				iban: 'GR2005610010000010005251023',
				currency: 'EUR',
			},
			bankTransactionCode: 'PMNT',
			remittanceInformationUnstructured: 'SMALL CHANGE',
		},
		schema: {
			type: 'object',
			required: ['transactionAmount'],
			properties: {
				entryReference: {
					type: 'string',
					description:
						'Is the identification of the transaction as used e.g. for reference for delta function on application level. The same identification as for example used within camt.05x messages.',
					example: 'c308ea08-7d4f-3656-a390-72448327223d',
				},
				checkId: {
					type: 'string',
					description: 'Identification of a Cheque',
					example: '22',
				},
				bookingDate: {
					type: 'string',
					example: '2017-10-25',
					description:
						'The Date when an entry is posted to an account on the ASPSPs books',
				},
				valueDate: {
					type: 'string',
					example: '2017-10-26',
					description:
						'The Date at which assets become available to the account owner in case of a credit',
				},
				transactionAmount: {
					description:
						'The amount of the transaction as billed to the account',
					type: 'object',
					required: ['currency', 'amount'],
					properties: {
						currency: {
							type: 'string',
							example: 'EUR',
							description: 'ISO 4217 Alpha 3 currency code',
						},
						amount: {
							type: 'string',
							example: '5768.2',
							description:
								'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
						},
					},
				},
				currencyExchange: {
					type: 'array',
					items: {
						type: 'object',
						required: [
							'sourceCurrency',
							'exchangeRate',
							'unitCurrency',
							'targetCurrency',
							'quotationDate',
						],
						properties: {
							sourceCurrency: {
								type: 'string',
								description:
									'Currency from which an amount is to be converted in a currency conversion.',
							},
							exchangeRate: {
								type: 'string',
								description:
									'Factor used to convert an amount from one currency into another. This reflects the price at which one currency was bought with another currency.',
							},
							unitCurrency: {
								type: 'string',
								description:
									'Currency in which the rate of exchange is expressed in a currency exchange. In the example 1EUR = xxxCUR, the unit currency is EUR.',
							},
							targetCurrency: {
								type: 'string',
								description:
									'Currency into which an amount is to be converted in a currency conversion.',
							},
							quotationDate: {
								type: 'string',
								description:
									'ISODate: A particular point in the progression of time in a calendar year expressed in the YYYY-MM-DD format.',
								format: 'date',
								example: '2017-10-25',
							},
							contractIdentification: {
								type: 'string',
								description:
									'Unique identification to unambiguously identify the foreign exchange contract.',
							},
						},
					},
					description: 'Only for Forex Transactions',
				},
				creditorName: {
					type: 'string',
					description:
						'Name of the creditor if a "Debited" transaction',
					example: 'VASILIS GEORGIADIS',
				},
				creditorAccount: {
					description: 'Account Reference of the creditor',
					type: 'object',
					properties: {
						iban: {
							type: 'string',
							example: 'GR2005610010000010005251023',
							description: 'IBAN',
							maximum: 35,
						},
						currency: {
							type: 'string',
							example: 'EUR',
							description: 'ISO 4217 Alpha 3 currency code',
						},
					},
				},
				debtorName: {
					type: 'string',
					description:
						'Name of the debtor if a "Credited" transaction',
					example: 'SILVER AGENT SA',
				},
				debtorAccount: {
					description: 'Account Reference of the debtor',
					type: 'object',
					properties: {
						$ref: '#/properties/creditorAccount/properties',
					},
				},
				bankTransactionCode: {
					type: 'string',
					description:
						'Bank transaction code as used by the ASPSP and using the sub elements of this structured code defined by ISO20022.',
					enum: [
						'PMNT Payments',
						'CAMT Cash Management',
						'DERV Derivatives',
						'LDAS Loans, Deposits & Syndications',
						'FORX Foreign Exchange',
						'PMET Precious Metal',
						'CMDT Commodities',
						'TRAD Trade Services',
						'SECU Securities',
						'ACMT Account Management',
						'XTND Extended Domain',
					],
					example: 'PMNT',
				},
				remittanceInformationUnstructured: {
					type: 'string',
					example: 'SMALL CHANGE',
				},
			},
		},
	},
	{
		id: 11,
		object: 'AccountTransactionListResponse',
		description:
			'Response for /v1/accounts/{account-id}/transactions request',
		example: {
			booked: [
				{
					entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
					checkId: '22',
					bookingDate: '2017-10-25',
					valueDate: '2017-10-26',
					transactionAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					currencyExchange: [
						{
							sourceCurrency: 'CAD',
							exchangeRate: 'asuhogrusenkuoh',
							unitCurrency: 'AED',
							targetCurrency: 'SOS',
							quotationDate: '2017-10-25',
							contractIdentification: '94541256327168',
						},
						{
							sourceCurrency: 'ERN',
							exchangeRate: 'vigetenkaa',
							unitCurrency: 'ANG',
							targetCurrency: 'BOB',
							quotationDate: '2017-10-25',
							contractIdentification: '3843871561744384',
						},
						{
							sourceCurrency: 'MXN',
							exchangeRate: 'etmamalfacift',
							unitCurrency: 'COP',
							targetCurrency: 'CVE',
							quotationDate: '2017-10-25',
							contractIdentification: '7919719612940288',
						},
					],
					creditorName: 'VASILIS GEORGIADIS',
					creditorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					debtorName: 'SILVER AGENT SA',
					debtorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					bankTransactionCode: 'PMNT',
					remittanceInformationUnstructured: 'SMALL CHANGE',
				},
				{
					entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
					checkId: '22',
					bookingDate: '2017-10-25',
					valueDate: '2017-10-26',
					transactionAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					currencyExchange: [
						{
							sourceCurrency: 'GNF',
							exchangeRate: 'wehikbekehsocluv',
							unitCurrency: 'MRO',
							targetCurrency: 'THB',
							quotationDate: '2017-10-25',
							contractIdentification: '5494946681323520',
						},
						{
							sourceCurrency: 'TRY',
							exchangeRate: 'nivpejipotgo',
							unitCurrency: 'GNF',
							targetCurrency: 'JEP',
							quotationDate: '2017-10-25',
							contractIdentification: '144602403176448',
						},
						{
							sourceCurrency: 'BIF',
							exchangeRate: 'mewg',
							unitCurrency: 'HNL',
							targetCurrency: 'HUF',
							quotationDate: '2017-10-25',
							contractIdentification: '8063400095514624',
						},
					],
					creditorName: 'VASILIS GEORGIADIS',
					creditorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					debtorName: 'SILVER AGENT SA',
					debtorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					bankTransactionCode: 'PMNT',
					remittanceInformationUnstructured: 'SMALL CHANGE',
				},
				{
					entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
					checkId: '22',
					bookingDate: '2017-10-25',
					valueDate: '2017-10-26',
					transactionAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					currencyExchange: [
						{
							sourceCurrency: 'GMD',
							exchangeRate: 'uctowepdi',
							unitCurrency: 'YER',
							targetCurrency: 'BRL',
							quotationDate: '2017-10-25',
							contractIdentification: '1876426047356928',
						},
						{
							sourceCurrency: 'USD',
							exchangeRate: 'wuwgu',
							unitCurrency: 'SZL',
							targetCurrency: 'DJF',
							quotationDate: '2017-10-25',
							contractIdentification: '423367574290432',
						},
						{
							sourceCurrency: 'TZS',
							exchangeRate: 'woipe',
							unitCurrency: 'UAH',
							targetCurrency: 'BRL',
							quotationDate: '2017-10-25',
							contractIdentification: '5051624953217024',
						},
					],
					creditorName: 'VASILIS GEORGIADIS',
					creditorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					debtorName: 'SILVER AGENT SA',
					debtorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					bankTransactionCode: 'PMNT',
					remittanceInformationUnstructured: 'SMALL CHANGE',
				},
			],
			pending: [
				{
					entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
					checkId: '22',
					bookingDate: '2017-10-25',
					valueDate: '2017-10-26',
					transactionAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					currencyExchange: [
						{
							sourceCurrency: 'CUP',
							exchangeRate: 'bonudkekludo',
							unitCurrency: 'CHF',
							targetCurrency: 'RSD',
							quotationDate: '2017-10-25',
							contractIdentification: '362993313382400',
						},
						{
							sourceCurrency: 'TZS',
							exchangeRate: 'sajpaacgu',
							unitCurrency: 'DOP',
							targetCurrency: 'LKR',
							quotationDate: '2017-10-25',
							contractIdentification: '43064674484224',
						},
						{
							sourceCurrency: 'SHP',
							exchangeRate: 'unuujumazn',
							unitCurrency: 'TVD',
							targetCurrency: 'COP',
							quotationDate: '2017-10-25',
							contractIdentification: '5533665733902336',
						},
					],
					creditorName: 'VASILIS GEORGIADIS',
					creditorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					debtorName: 'SILVER AGENT SA',
					debtorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					bankTransactionCode: 'PMNT',
					remittanceInformationUnstructured: 'SMALL CHANGE',
				},
				{
					entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
					checkId: '22',
					bookingDate: '2017-10-25',
					valueDate: '2017-10-26',
					transactionAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					currencyExchange: [
						{
							sourceCurrency: 'KRW',
							exchangeRate: 'efoinomas',
							unitCurrency: 'HNL',
							targetCurrency: 'LSL',
							quotationDate: '2017-10-25',
							contractIdentification: '4123016069906432',
						},
						{
							sourceCurrency: 'NOK',
							exchangeRate: 'ruisbega',
							unitCurrency: 'CDF',
							targetCurrency: 'PYG',
							quotationDate: '2017-10-25',
							contractIdentification: '866990222737408',
						},
						{
							sourceCurrency: 'JEP',
							exchangeRate: 'komuzuris',
							unitCurrency: 'DJF',
							targetCurrency: 'CUC',
							quotationDate: '2017-10-25',
							contractIdentification: '4254517625356288',
						},
					],
					creditorName: 'VASILIS GEORGIADIS',
					creditorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					debtorName: 'SILVER AGENT SA',
					debtorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					bankTransactionCode: 'PMNT',
					remittanceInformationUnstructured: 'SMALL CHANGE',
				},
				{
					entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
					checkId: '22',
					bookingDate: '2017-10-25',
					valueDate: '2017-10-26',
					transactionAmount: {
						currency: 'EUR',
						amount: '5768.2',
					},
					currencyExchange: [
						{
							sourceCurrency: 'THB',
							exchangeRate: 'ziwheg',
							unitCurrency: 'JPY',
							targetCurrency: 'TTD',
							quotationDate: '2017-10-25',
							contractIdentification: '7169432969281536',
						},
						{
							sourceCurrency: 'FKP',
							exchangeRate: 'nijaib',
							unitCurrency: 'ARS',
							targetCurrency: 'KES',
							quotationDate: '2017-10-25',
							contractIdentification: '3429785885736960',
						},
						{
							sourceCurrency: 'SDG',
							exchangeRate: 'lemujop',
							unitCurrency: 'NIO',
							targetCurrency: 'ETB',
							quotationDate: '2017-10-25',
							contractIdentification: '3409189548326912',
						},
					],
					creditorName: 'VASILIS GEORGIADIS',
					creditorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					debtorName: 'SILVER AGENT SA',
					debtorAccount: {
						iban: 'GR2005610010000010005251023',
						currency: 'EUR',
					},
					bankTransactionCode: 'PMNT',
					remittanceInformationUnstructured: 'SMALL CHANGE',
				},
			],
			_links: {
				account: {
					href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
				},
				download: {
					href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
				},
			},
		},
		schema: {
			type: 'object',
			required: ['_links'],
			properties: {
				booked: {
					type: 'array',
					description:
						'Shall be contained if bookingStatus parameter is set to "booked" or "both"',
					items: {
						type: 'object',
						required: ['transactionAmount'],
						properties: {
							entryReference: {
								type: 'string',
								description:
									'Is the identification of the transaction as used e.g. for reference for delta function on application level. The same identification as for example used within camt.05x messages.',
								example: 'c308ea08-7d4f-3656-a390-72448327223d',
							},
							checkId: {
								type: 'string',
								description: 'Identification of a Cheque',
								example: '22',
							},
							bookingDate: {
								type: 'string',
								example: '2017-10-25',
								description:
									'The Date when an entry is posted to an account on the ASPSPs books',
							},
							valueDate: {
								type: 'string',
								example: '2017-10-26',
								description:
									'The Date at which assets become available to the account owner in case of a credit',
							},
							transactionAmount: {
								description:
									'The amount of the transaction as billed to the account',
								type: 'object',
								required: ['currency', 'amount'],
								properties: {
									currency: {
										type: 'string',
										example: 'EUR',
										description:
											'ISO 4217 Alpha 3 currency code',
									},
									amount: {
										type: 'string',
										example: '5768.2',
										description:
											'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
									},
								},
							},
							currencyExchange: {
								type: 'array',
								items: {
									type: 'object',
									required: [
										'sourceCurrency',
										'exchangeRate',
										'unitCurrency',
										'targetCurrency',
										'quotationDate',
									],
									properties: {
										sourceCurrency: {
											type: 'string',
											description:
												'Currency from which an amount is to be converted in a currency conversion.',
										},
										exchangeRate: {
											type: 'string',
											description:
												'Factor used to convert an amount from one currency into another. This reflects the price at which one currency was bought with another currency.',
										},
										unitCurrency: {
											type: 'string',
											description:
												'Currency in which the rate of exchange is expressed in a currency exchange. In the example 1EUR = xxxCUR, the unit currency is EUR.',
										},
										targetCurrency: {
											type: 'string',
											description:
												'Currency into which an amount is to be converted in a currency conversion.',
										},
										quotationDate: {
											type: 'string',
											description:
												'ISODate: A particular point in the progression of time in a calendar year expressed in the YYYY-MM-DD format.',
											format: 'date',
											example: '2017-10-25',
										},
										contractIdentification: {
											type: 'string',
											description:
												'Unique identification to unambiguously identify the foreign exchange contract.',
										},
									},
								},
								description: 'Only for Forex Transactions',
							},
							creditorName: {
								type: 'string',
								description:
									'Name of the creditor if a "Debited" transaction',
								example: 'VASILIS GEORGIADIS',
							},
							creditorAccount: {
								description:
									'Account Reference of the creditor',
								type: 'object',
								properties: {
									iban: {
										type: 'string',
										example: 'GR2005610010000010005251023',
										description: 'IBAN',
										maximum: 35,
									},
									currency: {
										type: 'string',
										example: 'EUR',
										description:
											'ISO 4217 Alpha 3 currency code',
									},
								},
							},
							debtorName: {
								type: 'string',
								description:
									'Name of the debtor if a "Credited" transaction',
								example: 'SILVER AGENT SA',
							},
							debtorAccount: {
								description: 'Account Reference of the debtor',
								type: 'object',
								properties: {
									$ref: '#/properties/booked/items/properties/creditorAccount/properties',
								},
							},
							bankTransactionCode: {
								type: 'string',
								description:
									'Bank transaction code as used by the ASPSP and using the sub elements of this structured code defined by ISO20022.',
								enum: [
									'PMNT Payments',
									'CAMT Cash Management',
									'DERV Derivatives',
									'LDAS Loans, Deposits & Syndications',
									'FORX Foreign Exchange',
									'PMET Precious Metal',
									'CMDT Commodities',
									'TRAD Trade Services',
									'SECU Securities',
									'ACMT Account Management',
									'XTND Extended Domain',
								],
								example: 'PMNT',
							},
							remittanceInformationUnstructured: {
								type: 'string',
								example: 'SMALL CHANGE',
							},
						},
					},
				},
				pending: {
					type: 'array',
					description:
						'Not contained if the bookingStatus parameter is set to "booked"',
					items: {
						$ref: '#/properties/booked/items',
					},
				},
				_links: {
					description:
						'A list of hyperlinks to be recognised by the TPP. Type of links admitted in this response ["download" a link to a resource, where the transaction report might be downloaded from in case where transaction reports have a huge size.]',
					properties: {
						account: {
							properties: {
								href: {
									type: 'string',
									example:
										'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
								},
							},
							additionalProperties: false,
						},
						download: {
							$ref: '#/properties/_links/properties/account',
						},
					},
					additionalProperties: false,
				},
			},
		},
	},
	{
		id: 12,
		object: 'ExchangeRate',
		description: null,
		example: {
			account: {
				iban: 'GR2005610010000010005251023',
				currency: 'EUR',
			},
			transactions: {
				booked: [
					{
						entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
						checkId: '22',
						bookingDate: '2017-10-25',
						valueDate: '2017-10-26',
						transactionAmount: {
							currency: 'EUR',
							amount: '5768.2',
						},
						currencyExchange: [
							{
								sourceCurrency: 'JMD',
								exchangeRate: 'loleve',
								unitCurrency: 'FJD',
								targetCurrency: 'TJS',
								quotationDate: '2017-10-25',
								contractIdentification: '3160667815149568',
							},
							{
								sourceCurrency: 'SHP',
								exchangeRate: 'ugjuzmuppe',
								unitCurrency: 'NGN',
								targetCurrency: 'SOS',
								quotationDate: '2017-10-25',
								contractIdentification: '4959789733904384',
							},
							{
								sourceCurrency: 'PAB',
								exchangeRate: 'emfezil',
								unitCurrency: 'MOP',
								targetCurrency: 'BBD',
								quotationDate: '2017-10-25',
								contractIdentification: '736549832491008',
							},
						],
						creditorName: 'VASILIS GEORGIADIS',
						creditorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						debtorName: 'SILVER AGENT SA',
						debtorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						bankTransactionCode: 'PMNT',
						remittanceInformationUnstructured: 'SMALL CHANGE',
					},
					{
						entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
						checkId: '22',
						bookingDate: '2017-10-25',
						valueDate: '2017-10-26',
						transactionAmount: {
							currency: 'EUR',
							amount: '5768.2',
						},
						currencyExchange: [
							{
								sourceCurrency: 'STD',
								exchangeRate: 'ambesmokejei',
								unitCurrency: 'EGP',
								targetCurrency: 'BBD',
								quotationDate: '2017-10-25',
								contractIdentification: '5088908639469568',
							},
							{
								sourceCurrency: 'MOP',
								exchangeRate: 'domn',
								unitCurrency: 'GTQ',
								targetCurrency: 'BSD',
								quotationDate: '2017-10-25',
								contractIdentification: '3870146928050176',
							},
							{
								sourceCurrency: 'LKR',
								exchangeRate: 'fuawauce',
								unitCurrency: 'INR',
								targetCurrency: 'ANG',
								quotationDate: '2017-10-25',
								contractIdentification: '2476191074222080',
							},
						],
						creditorName: 'VASILIS GEORGIADIS',
						creditorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						debtorName: 'SILVER AGENT SA',
						debtorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						bankTransactionCode: 'PMNT',
						remittanceInformationUnstructured: 'SMALL CHANGE',
					},
					{
						entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
						checkId: '22',
						bookingDate: '2017-10-25',
						valueDate: '2017-10-26',
						transactionAmount: {
							currency: 'EUR',
							amount: '5768.2',
						},
						currencyExchange: [
							{
								sourceCurrency: 'JMD',
								exchangeRate: 'igec',
								unitCurrency: 'XPF',
								targetCurrency: 'GEL',
								quotationDate: '2017-10-25',
								contractIdentification: '8975901601562624',
							},
							{
								sourceCurrency: 'DZD',
								exchangeRate: 'jorigujkiti',
								unitCurrency: 'VND',
								targetCurrency: 'VUV',
								quotationDate: '2017-10-25',
								contractIdentification: '2456302072627200',
							},
							{
								sourceCurrency: 'NAD',
								exchangeRate: 'viak',
								unitCurrency: 'LBP',
								targetCurrency: 'XAF',
								quotationDate: '2017-10-25',
								contractIdentification: '3628541509894144',
							},
						],
						creditorName: 'VASILIS GEORGIADIS',
						creditorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						debtorName: 'SILVER AGENT SA',
						debtorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						bankTransactionCode: 'PMNT',
						remittanceInformationUnstructured: 'SMALL CHANGE',
					},
				],
				pending: [
					{
						entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
						checkId: '22',
						bookingDate: '2017-10-25',
						valueDate: '2017-10-26',
						transactionAmount: {
							currency: 'EUR',
							amount: '5768.2',
						},
						currencyExchange: [
							{
								sourceCurrency: 'ALL',
								exchangeRate: 'vanuwaebu',
								unitCurrency: 'DZD',
								targetCurrency: 'TJS',
								quotationDate: '2017-10-25',
								contractIdentification: '1091416170168320',
							},
							{
								sourceCurrency: 'AZN',
								exchangeRate: 'womuel',
								unitCurrency: 'HUF',
								targetCurrency: 'UAH',
								quotationDate: '2017-10-25',
								contractIdentification: '8014786327478272',
							},
							{
								sourceCurrency: 'AED',
								exchangeRate: 'dorimoal',
								unitCurrency: 'SCR',
								targetCurrency: 'GIP',
								quotationDate: '2017-10-25',
								contractIdentification: '4880268158566400',
							},
						],
						creditorName: 'VASILIS GEORGIADIS',
						creditorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						debtorName: 'SILVER AGENT SA',
						debtorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						bankTransactionCode: 'PMNT',
						remittanceInformationUnstructured: 'SMALL CHANGE',
					},
					{
						entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
						checkId: '22',
						bookingDate: '2017-10-25',
						valueDate: '2017-10-26',
						transactionAmount: {
							currency: 'EUR',
							amount: '5768.2',
						},
						currencyExchange: [
							{
								sourceCurrency: 'LTL',
								exchangeRate: 'tomuzsili',
								unitCurrency: 'UGX',
								targetCurrency: 'RON',
								quotationDate: '2017-10-25',
								contractIdentification: '8518909749624832',
							},
							{
								sourceCurrency: 'NGN',
								exchangeRate: 'abijujo',
								unitCurrency: 'FJD',
								targetCurrency: 'LTL',
								quotationDate: '2017-10-25',
								contractIdentification: '224716197462016',
							},
							{
								sourceCurrency: 'JOD',
								exchangeRate: 'ifilodl',
								unitCurrency: 'LBP',
								targetCurrency: 'NOK',
								quotationDate: '2017-10-25',
								contractIdentification: '1749105982308352',
							},
						],
						creditorName: 'VASILIS GEORGIADIS',
						creditorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						debtorName: 'SILVER AGENT SA',
						debtorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						bankTransactionCode: 'PMNT',
						remittanceInformationUnstructured: 'SMALL CHANGE',
					},
					{
						entryReference: 'c308ea08-7d4f-3656-a390-72448327223d',
						checkId: '22',
						bookingDate: '2017-10-25',
						valueDate: '2017-10-26',
						transactionAmount: {
							currency: 'EUR',
							amount: '5768.2',
						},
						currencyExchange: [
							{
								sourceCurrency: 'SAR',
								exchangeRate: 'muahowiwihazap',
								unitCurrency: 'ANG',
								targetCurrency: 'AMD',
								quotationDate: '2017-10-25',
								contractIdentification: '2227615637176320',
							},
							{
								sourceCurrency: 'TTD',
								exchangeRate: 'zofuwu',
								unitCurrency: 'BZD',
								targetCurrency: 'BOB',
								quotationDate: '2017-10-25',
								contractIdentification: '7202353499340800',
							},
							{
								sourceCurrency: 'KGS',
								exchangeRate: 'hosobipkefemie',
								unitCurrency: 'USD',
								targetCurrency: 'GYD',
								quotationDate: '2017-10-25',
								contractIdentification: '7524941075316736',
							},
						],
						creditorName: 'VASILIS GEORGIADIS',
						creditorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						debtorName: 'SILVER AGENT SA',
						debtorAccount: {
							iban: 'GR2005610010000010005251023',
							currency: 'EUR',
						},
						bankTransactionCode: 'PMNT',
						remittanceInformationUnstructured: 'SMALL CHANGE',
					},
				],
				_links: {
					account: {
						href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
					},
					download: {
						href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
					},
				},
			},
		},
		schema: {
			type: 'object',
			properties: {
				account: {
					description:
						'Identifier of the addressed account. Remark for Future: It is recommended to use this data element. The condition might change to "mandatory" in a next version of the specification.',
					type: 'object',
					properties: {
						iban: {
							type: 'string',
							example: 'GR2005610010000010005251023',
							description: 'IBAN',
							maximum: 35,
						},
						currency: {
							type: 'string',
							example: 'EUR',
							description: 'ISO 4217 Alpha 3 currency code',
						},
					},
				},
				transactions: {
					type: 'object',
					required: ['_links'],
					properties: {
						booked: {
							type: 'array',
							description:
								'Shall be contained if bookingStatus parameter is set to "booked" or "both"',
							items: {
								type: 'object',
								required: ['transactionAmount'],
								properties: {
									entryReference: {
										type: 'string',
										description:
											'Is the identification of the transaction as used e.g. for reference for delta function on application level. The same identification as for example used within camt.05x messages.',
										example:
											'c308ea08-7d4f-3656-a390-72448327223d',
									},
									checkId: {
										type: 'string',
										description:
											'Identification of a Cheque',
										example: '22',
									},
									bookingDate: {
										type: 'string',
										example: '2017-10-25',
										description:
											'The Date when an entry is posted to an account on the ASPSPs books',
									},
									valueDate: {
										type: 'string',
										example: '2017-10-26',
										description:
											'The Date at which assets become available to the account owner in case of a credit',
									},
									transactionAmount: {
										description:
											'The amount of the transaction as billed to the account',
										type: 'object',
										required: ['currency', 'amount'],
										properties: {
											currency: {
												type: 'string',
												example: 'EUR',
												description:
													'ISO 4217 Alpha 3 currency code',
											},
											amount: {
												type: 'string',
												example: '5768.2',
												description:
													'The amount given with fractional digits, where fractions must be compliant to the currency definition.   Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.   Example:  Valid representations for EUR with up to two decimals are: • 1056  • 5768.2  • -1.50  • 5877.78',
											},
										},
									},
									currencyExchange: {
										type: 'array',
										items: {
											type: 'object',
											required: [
												'sourceCurrency',
												'exchangeRate',
												'unitCurrency',
												'targetCurrency',
												'quotationDate',
											],
											properties: {
												sourceCurrency: {
													type: 'string',
													description:
														'Currency from which an amount is to be converted in a currency conversion.',
												},
												exchangeRate: {
													type: 'string',
													description:
														'Factor used to convert an amount from one currency into another. This reflects the price at which one currency was bought with another currency.',
												},
												unitCurrency: {
													type: 'string',
													description:
														'Currency in which the rate of exchange is expressed in a currency exchange. In the example 1EUR = xxxCUR, the unit currency is EUR.',
												},
												targetCurrency: {
													type: 'string',
													description:
														'Currency into which an amount is to be converted in a currency conversion.',
												},
												quotationDate: {
													type: 'string',
													description:
														'ISODate: A particular point in the progression of time in a calendar year expressed in the YYYY-MM-DD format.',
													format: 'date',
													example: '2017-10-25',
												},
												contractIdentification: {
													type: 'string',
													description:
														'Unique identification to unambiguously identify the foreign exchange contract.',
												},
											},
										},
										description:
											'Only for Forex Transactions',
									},
									creditorName: {
										type: 'string',
										description:
											'Name of the creditor if a "Debited" transaction',
										example: 'VASILIS GEORGIADIS',
									},
									creditorAccount: {
										description:
											'Account Reference of the creditor',
										type: 'object',
										properties: {
											$ref: '#/properties/account/properties',
										},
									},
									debtorName: {
										type: 'string',
										description:
											'Name of the debtor if a "Credited" transaction',
										example: 'SILVER AGENT SA',
									},
									debtorAccount: {
										description:
											'Account Reference of the debtor',
										type: 'object',
										properties: {
											$ref: '#/properties/account/properties',
										},
									},
									bankTransactionCode: {
										type: 'string',
										description:
											'Bank transaction code as used by the ASPSP and using the sub elements of this structured code defined by ISO20022.',
										enum: [
											'PMNT Payments',
											'CAMT Cash Management',
											'DERV Derivatives',
											'LDAS Loans, Deposits & Syndications',
											'FORX Foreign Exchange',
											'PMET Precious Metal',
											'CMDT Commodities',
											'TRAD Trade Services',
											'SECU Securities',
											'ACMT Account Management',
											'XTND Extended Domain',
										],
										example: 'PMNT',
									},
									remittanceInformationUnstructured: {
										type: 'string',
										example: 'SMALL CHANGE',
									},
								},
							},
						},
						pending: {
							type: 'array',
							description:
								'Not contained if the bookingStatus parameter is set to "booked"',
							items: {
								$ref: '#/properties/transactions/properties/booked/items',
							},
						},
						_links: {
							description:
								'A list of hyperlinks to be recognised by the TPP. Type of links admitted in this response ["download" a link to a resource, where the transaction report might be downloaded from in case where transaction reports have a huge size.]',
							properties: {
								account: {
									properties: {
										href: {
											type: 'string',
											example:
												'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
										},
									},
									additionalProperties: false,
								},
								download: {
									$ref: '#/properties/transactions/properties/_links/properties/account',
								},
							},
							additionalProperties: false,
						},
					},
				},
			},
			description:
				'Response for /v1/accounts/{account-id}/transactions request',
		},
	},
	{
		id: 13,
		object: 'href',
		description: null,
		example: {
			href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
		},
		schema: {
			properties: {
				href: {
					type: 'string',
					example:
						'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
				},
			},
			additionalProperties: false,
		},
	},
	{
		id: 15,
		object: 'Transactions links',
		description: null,
		example: {
			account: {
				href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
			},
			download: {
				href: '":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
			},
		},
		schema: {
			properties: {
				account: {
					properties: {
						href: {
							type: 'string',
							example:
								'":"/v1/accounts/3dc3d5b3-7023-f5400a80f/transactions',
						},
					},
					additionalProperties: false,
				},
				download: {
					$ref: '#/properties/account',
				},
			},
			additionalProperties: false,
		},
	},
];
