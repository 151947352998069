import React, { useContext } from 'react';
import 'devextreme/data/odata/store';
import { apiBaseUrl } from '../../config/globalVariables';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	SearchPanel,
	FilterRow,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	Summary,
	GroupItem,
	ColumnFixing,
	ColumnChooser,
	Lookup,
	DataGridTypes,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { showNotification } from '../../utils/showNotification'; // Ajusta la ruta de importación según sea necesario
import { useAuth } from '../../contexts/auth';
import { UiConfigContext } from '../../contexts/uiConfig';

export default function Clientes() {
	const { signOut } = useAuth();
	const { uiConfig } = useContext(UiConfigContext);

	const clientsDataSource = {
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}clients`,
			beforeSend: function (request: any) {
				request.headers = {
					'X-RshkMichi-User-Token': sessionStorage.getItem('user_access_token'),
				};
			},
			errorHandler: function (error: any) {
				console.log(error.httpStatus);
				if (error.httpStatus === 401) {
					showNotification({
						message:
							'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
						type: 'error',
						displayTime: 12000,
						position: uiConfig.notificationsPosition,
					});
					signOut();
				}
			},
		},
		select: ['id', 'name', 'email', 'birthDate', 'birthPlaceId', 'salary'],
	};

	const citiesDataSource = {
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}cities`,
			errorHandler: function (error: any) {
				console.log(error.httpStatus);
				if (error.httpStatus === 401) {
					showNotification({
						message:
							'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
						type: 'error',
						displayTime: 12000,
						position: uiConfig.notificationsPosition,
					});
					signOut();
				}
			},
		},
		select: ['id', 'name'],
	};

	const pageSizes = [5, 7, 10, 25, 50, 100];

	const onExporting = (e: DataGridTypes.ExportingEvent) => {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');

		exportDataGrid({
			component: e.component,
			worksheet,
			autoFilterEnabled: true,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], { type: 'application/octet-stream' }),
					'DataGrid.xlsx'
				);
			});
		});
	};

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Clientes</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<DataGrid
						dataSource={clientsDataSource as any}
						showBorders={false}
						focusedRowEnabled={false}
						columnAutoWidth={true}
						columnHidingEnabled={true}
						rowAlternationEnabled={true}
						hoverStateEnabled={true}
						allowColumnReordering={true}
						width="100%"
						className="dx-datagrid-filter-top"
						onExporting={onExporting}
					>
						<Scrolling mode="standard" />
						{/*"standard" or "virtual" | "infinite" los ultimos dos requieren tener height configurado */}
						<GroupPanel visible={true} />
						<FilterRow visible={true} />
						<FilterPanel visible={true} />
						<HeaderFilter visible={false} />
						<ColumnFixing enabled={true} />
						<ColumnChooser enabled={true} />
						<SearchPanel
							visible={true}
							highlightCaseSensitive={true}
							width={240}
						/>
						<Grouping autoExpandAll={true} />
						<RemoteOperations
							filtering={true}
							paging={true}
							sorting={true}
							summary={false}
							grouping={false}
							groupPaging={false}
						/>
						<Column
							dataField={'id'}
							width={90}
							hidingPriority={1}
							caption={'Id'}
						/>
						<Column
							hidingPriority={6}
							dataField={'name'}
							caption={'Nombre'}
						/>
						<Column
							hidingPriority={5}
							dataField={'email'}
							caption={'Email'}
						/>
						<Column
							hidingPriority={2}
							dataField={'birthDate'}
							caption={'Fecha de Nacimiento'}
							format="dd/MM/yyyy"
						/>
						<Column
							dataField="birthPlaceId"
							caption="Lugar de Nacimiento"
						>
							<Lookup
								dataSource={citiesDataSource as any}
								displayExpr="name"
								valueExpr="id"
							/>
						</Column>
						<Column
							hidingPriority={3}
							dataField={'salary'}
							caption={'Salario'}
							dataType="number"
							format="₲ ###,###,##0"
							alignment="right"
						/>
						<Summary>
							<GroupItem column="id" summaryType="count" />
							<GroupItem
								column="salary"
								summaryType="sum"
								valueFormat="₲ ###,###,##0.##"
								displayFormat="Total de la Ciudad: {0}"
							/>
						</Summary>
						<Pager
							allowedPageSizes={pageSizes}
							showPageSizeSelector={true}
							showNavigationButtons={true}
							showInfo={true}
							displayMode="compact"
						/>
						<Paging defaultPageSize={7} />
						<Export enabled={true} />
					</DataGrid>
				</div>
			</div>
		</React.Fragment>
	);
}
