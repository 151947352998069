/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Form, {
	Item,
	Label,
	ButtonItem,
	ButtonOptions,
	RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import './LoginForm.scss';
import { TextBoxTypes } from 'devextreme-react/text-box';
import { defaultShowTimeSToast } from '../../config/globalVariables';

export default function LoginForm() {
	const { signIn } = useAuth();
	const [loading, setLoading] = useState(false);
	const formData = useRef({ email: '', password: '' });
	const [passwordMode, setPasswordMode] =
		useState<TextBoxTypes.TextBoxType>('password');

	const onSubmit = useCallback(
		async (e: any) => {
			e.preventDefault();
			const { email, password } = formData.current;
			setLoading(true);

			const result = await signIn(email, password);
			if (!result.isOk) {
				setLoading(false);
				notify(result.message, 'error', defaultShowTimeSToast);
			}
		},
		[signIn]
	);

	// efecto para sobreescribir el background del login con el general
	useEffect(() => {
		// Cuando el componente se monta, agrega la clase
		document.getElementById('app')?.classList.add('app-login');

		// Cuando el componente se desmonta, remueve la clase
		return () => {
			document.getElementById('app')?.classList.remove('app-login');
		};
	}, []);

	let emailEditorOptions = {
		stylingMode: 'outlined',
		labelMode: 'outside',
		//placeholder: 'USUARIO',
		mode: 'text',
		height: '50px',
		showClearButton: false,
	};

	// Usar useMemo para evitar recrear el objeto en cada renderizado
	let passwordEditorOptions = useMemo(
		() => ({
			stylingMode: 'outlined',
			labelMode: 'outside',
			//placeholder: 'CONTRASEÑA',
			mode: passwordMode, // Asigna directamente el estado que controla si es texto visible o password
			height: '50px',
			showClearButton: false,
			buttons: [
				{
					name: 'passwordVisibilityToggle',
					location: 'after',
					options: {
						icon: passwordMode === 'text' ? 'eyeopen' : 'eyeclose',
						onClick: () => {
							setPasswordMode(
								passwordMode === 'text' ? 'password' : 'text'
							);
						},
						elementAttr: {
							style: {
								backgroundColor: 'transparent',
								color: 'black',
								border: 'none !important',
								cursor: 'pointer',
								margin: 'none',
							},
						},
					},
				},
			],
		}),
		[passwordMode]
	);

	let rememberMeEditorOptions = {
		text: 'Recordarme',
		elementAttr: { class: 'form-text' },
	};

	// ese useEffect se ejecuta solo la primera vez que se monta la pagina,
	// deja que la animacion de animate.css se ejecute 1 vez y luego elimina las clases necesarias
	const [hasAnimated, setHasAnimated] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			const loginCard = document.querySelector('.login-form');
			if (loginCard === null) return;

			loginCard
				.querySelectorAll('.item-login_animated')
				.forEach((el) => el.classList.remove('animate__animated'));

			setHasAnimated(true);
		}, 1500);
	}, []);

	return (
		<form className={'login-form'} onSubmit={onSubmit}>
			<Form
				formData={formData.current}
				disabled={loading}
				showValidationSummary={false}
			>
				<Item
					dataField="email" // Usar nombres constantes para dataField que correspondan con las propiedades en formData
					editorType={'dxTextBox'}
					editorOptions={emailEditorOptions}
					cssClass={
						'item-login_animated textbox-login-label' +
						(!hasAnimated
							? 'animate__animated animate__flipInX animate__delay-1s'
							: '')
					}
				>
					<RequiredRule message="El usuario es requerido" />
					<Label visible={false} text="Usuario" />
				</Item>
				<Item
					dataField="password" // Este debería ser constante y no cambiar según el tipo de input
					editorType={'dxTextBox'}
					editorOptions={passwordEditorOptions}
					cssClass={
						'item-login_animated textbox-login-label' +
						(!hasAnimated
							? 'animate__animated animate__flipInX animate__delay-1s'
							: '')
					}
				>
					<RequiredRule message="La contraseña es requerida" />
					<Label visible={false} text="Contraseña" />
				</Item>
				<Item
					dataField={'rememberMe'}
					editorType={'dxCheckBox'}
					editorOptions={rememberMeEditorOptions}
					visible={false}
					cssClass="login-item-buttom"
				>
					<Label visible={false} />
				</Item>
				<ButtonItem
					cssClass={
						'item-login_animated login-item-buttom ' +
						(!hasAnimated
							? 'animate__animated animate__flipInX animate__delay-1s'
							: '')
					}
				>
					<ButtonOptions
						width={'100%'}
						height={'50px'}
						type={'default'}
						useSubmitBehavior={true}
					>
						<span className="dx-button-text">
							{loading ? (
								<LoadIndicator
									width={'24px'}
									height={'24px'}
									visible={true}
								/>
							) : (
								'INGRESAR'
							)}
						</span>
					</ButtonOptions>
				</ButtonItem>
				<Item cssClass="item-forgot-passwrod" visible={false}>
					<div
						className={
							'link forgot-password item-login_animated ' +
							(!hasAnimated
								? 'animate__animated animate__flipInX animate__delay-1s'
								: '')
						}
					>
						<Link to={'/reset-password'}>
							¿Olvidaste tu contraseña?
						</Link>
					</div>
				</Item>
			</Form>
		</form>
	);
}
