import { createContext } from 'react';

export const ThemeList = ['light', 'blue', 'green-teal'] as const;
export type Theme = (typeof ThemeList)[number];
interface ThemeContextType {
	theme: Theme;
	setTheme: (theme: Theme) => void;
}

export const DefaultTheme = 'blue';

const defaultValue: ThemeContextType = {
	theme: DefaultTheme,
	setTheme: (theme: Theme) => console.log(theme),
};

export const ThemeContext = createContext<ThemeContextType>(defaultValue);
