import React, { useContext, useState } from 'react';
import './profile.scss';
import Form  from 'devextreme-react/form';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import { showNotification } from '../../utils/showNotification';
import { Theme, ThemeContext } from '../../contexts/theme';
import { UiConfigContext, UiOptions } from '../../contexts/uiConfig';
import { LabelMode } from 'devextreme/common';

export default function Profile() {
	const [notes, setNotes] = useState(
		'Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you`ve not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.'
	);
	const employee = {
		ID: 7,
		FirstName: 'Sandra',
		LastName: 'Johnson',
		Prefix: 'Mrs.',
		Position: 'Controller',
		Picture: 'images/employees/06.png',
		BirthDate: new Date('1974/11/5'),
		HireDate: new Date('2005/05/11'),
		Notes: notes,
		Address: '4600 N Virginia Rd.',
	};

	const onClick = (e: ButtonTypes.ClickEvent) => {
		const type = e.component.option('hint');
		console.log(type);

		if (type === 'info' || type === 'all')
			showNotification({
				message: 'Mensaje de Información de Prueba',
				type: 'info',
				position: uiConfig.notificationsPosition,
			});

		if (type === 'success' || type === 'all')
			showNotification({
				message: 'Mensaje de Éxito de Prueba',
				type: 'success',
				displayTime: 3000,
				position: uiConfig.notificationsPosition,
			});

		if (type === 'warning' || type === 'all')
			showNotification({
				message: 'Mensaje de Advertencia de Prueba',
				type: 'warning',
				displayTime: 5000,
				position: uiConfig.notificationsPosition,
			});

		if (type === 'error' || type === 'all')
			showNotification({
				message: 'Mensaje de Error de Prueba',
				type: 'error',
				displayTime: 10000,
				position: uiConfig.notificationsPosition,
			});
	};

	const { setTheme } = useContext(ThemeContext);
	const { uiConfig, setUiConfig } = useContext(UiConfigContext);


	function changeTheme(theme: Theme) {
		localStorage.setItem('theme', theme);
		console.log(theme);
		setTheme(theme);
	}


	function changeUiConfig(newConfig: any, reload: boolean) {
	 	const updatedConfig = { ...uiConfig, ...newConfig };
	 	localStorage.setItem('ui_config', JSON.stringify(updatedConfig));
	 	setUiConfig(updatedConfig);
	 	if (reload) window.location.reload();
	}

	return (
		<React.Fragment>
			<h2 className={'content-block'}>Configuración del Sistema</h2>

			<div
				className={
					'content-block dx-card responsive-paddings animate__animated animate__fadeIn'
				}
			>
				<h6 className="title-card">Pruebas de Notificaciones</h6>

				<div className="flex-container">
					<Button
						icon="info"
						text="Test Info"
						hint="info"
						type="normal"
						stylingMode="contained"
						onClick={onClick}
						height={40}
						className="btn-test"
						width={'100%'}
					/>
					<span style={{ margin: '5px' }}></span>
					<Button
						icon="check"
						text="Test Success"
						hint="success"
						type="success"
						stylingMode="contained"
						onClick={onClick}
						height={40}
						className="btn-test"
						width={'100%'}
					/>
					<span style={{ margin: '5px' }}></span>
					<Button
						icon="warning"
						text="Test Advertencia"
						className="btnTestWarning btn-test"
						hint="warning"
						type="danger"
						stylingMode="outlined"
						onClick={onClick}
						height={40}
						width={'100%'}
					/>
					<span style={{ margin: '5px' }}></span>
					<Button
						icon="close"
						text="Test Error"
						hint="error"
						type="danger"
						stylingMode="contained"
						onClick={onClick}
						height={40}
						className="btn-test"
						width={'100%'}
					/>
					<span style={{ margin: '5px' }}></span>
					<Button
						icon="contentlayout"
						text="Test Todos"
						hint="all"
						type="default"
						stylingMode="contained"
						onClick={onClick}
						height={40}
						className="btn-test"
						width={'100%'}
					/>
				</div>
			</div>

			<div
				className={
					'content-block dx-card responsive-paddings animate__animated animate__fadeIn'
				}
			>
				<h6 className="title-card">Paleta de Colores</h6>

				<div className="flex-container">
					<Button
						icon="mdi mdi-palette"
						text="Tema Claro"
						hint="Tema Claro"
						type="normal"
						stylingMode="contained"
						onClick={() => {
							changeTheme('light');
						}}
						height={40}
						width={'100%'}
					/>
					<span style={{ margin: '5px' }}></span>
					<Button
						icon="mdi mdi-palette"
						text="Tema Azul"
						hint="Tema Azul"
						type="default"
						stylingMode="contained"
						onClick={() => {
							changeTheme('blue');
						}}
						height={40}
						width={'100%'}
					/>
					<span style={{ margin: '5px' }}></span>
					<Button
						icon="mdi mdi-palette"
						text="Tema Verde Cerdeta"
						hint="Tema Verde Cerdeta"
						type="default"
						className="btn-green-teal"
						stylingMode="contained"
						onClick={() => {
							changeTheme('green-teal');
						}}
						height={40}
						width={'100%'}
					/>
				</div>
			</div>

			<div 
				className={
					'content-block dx-card responsive-paddings animate__animated animate__fadeIn'
				}
			>
				<h6 className="title-card">Configuración de UI</h6>

				<p className="subtitle">Inputs</p>


				<div className="flex-container form-spacing">
					<SelectBox
						dataSource={UiOptions.stylingMode}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona un estilo"
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.stylingMode}
						onValueChanged={(e) =>
							changeUiConfig({ stylingMode: e.value }, true)
						}
						width="100%"
					/>
				</div>


				<div className="flex-container form-spacing">
					<SelectBox
						dataSource={UiOptions.labelMode}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona un modo de etiqueta"
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.labelMode}
						onValueChanged={(e) =>
							changeUiConfig({ labelMode: e.value }, false)
						}
						width="100%"
					/>
				</div>

				<p className='subtitle'>Grids</p>


				<div className="flex-container form-spacing">
					<NumberBox 
						value={uiConfig.dataGrid.defaultRowsPerPage}
						min={5}
						max={20}
						showSpinButtons={true}
						label="Selecciona la cantidad de filas de las grillas"
						labelMode={uiConfig.labelMode as LabelMode}
						onValueChanged={(e) => 
							changeUiConfig({ dataGrid: { 
								...uiConfig.dataGrid,
								defaultRowsPerPage: e.value 
							}}, false)
						}
						width="100%"
					/>
					
				</div>

				<div className="flex-container form-spacing">
					<SelectBox 
						dataSource={UiOptions.defaultFilterVisibility}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona la visibilidad de filtros en las grillas" 
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.dataGrid.defaultFilterVisibility}
						onValueChanged={(e) =>
							changeUiConfig({ dataGrid: { 
								...uiConfig.dataGrid,
								defaultFilterVisibility: e.value 
							}}, false)
						}
						width="100%"
					/>
					
				</div>

				<p className='subtitle'>Notificaciones</p>

				<div className="flex-container form-spacing">
					<SelectBox 
						dataSource={UiOptions.notificationsPosition}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona la posicion de las notificaciones" 
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.notificationsPosition}
						onValueChanged={(e) =>
							changeUiConfig({ notificationsPosition: e.value }, false)
						}
						width="100%"
					/>
					
				</div>

				<p className='subtitle'>Menu</p>

				<div className="flex-container form-spacing">
					<SelectBox 
						dataSource={UiOptions.menuStatus}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona el estado del menu" 
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.menuStatus}
						onValueChanged={(e) =>
							changeUiConfig({ menuStatus: e.value }, true)
						}
						width="100%"
					/>
					
				</div>
				
			</div>

			<div
				className={
					'content-block dx-card responsive-paddings animate__animated animate__fadeIn'
				}
			>
				<Form
					id={'form'}
					defaultFormData={employee}
					onFieldDataChanged={(e) =>
						e.dataField === 'Notes' && setNotes(e.value)
					}
					labelMode={uiConfig.labelMode as LabelMode}
					colCountByScreen={colCountByScreen}
				/>
			</div>
		</React.Fragment>
	);
}

const colCountByScreen = {
	xs: 1,
	sm: 2,
	md: 3,
	lg: 4,
};
