import React from 'react';
import { Button } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';

interface ActionCellProps {
  data: any;
  userPermissions: any;
}

const ActionCell: React.FC<ActionCellProps> = ({ data, userPermissions }) => {
  const navigate = useNavigate();

  const renderPermissionButton = () => {
    return (
      <Button
        stylingMode={'outlined'}
        onClick={() => navigate('/apikeys/permissions')}
        className="finnekta-button-action"
        disabled={!userPermissions.canEnableApiKey}
      >
        <span className="mdi mdi-account-check-outline"></span>
        <span>Permisos</span>
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <Button
        stylingMode={'outlined'}
        onClick={() =>
          navigate('/apikeys/edit', { state: { apiKeyData: data } })
        }
        className="finnekta-button-action"
        disabled={!userPermissions.canEditApiKey}
      >
        <span className="mdi mdi-pencil-outline"></span>
        <span>Editar</span>
      </Button>
    );
  };

  return (
    <div className={'button-action-container'}>
      {renderPermissionButton()}
      {renderEditButton()}
    </div>
  );
};

export default ActionCell;
