import './errors.scss';

export default function NotFound() {
    return (
            <div className='error-page complete'>
                <div className="error-message">
                    <h1>404</h1>
                    <h4>Página no encontrada</h4>
                    <a href="#/home">Volver al inicio</a>
                </div>
            </div>
    )
}