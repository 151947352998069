import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import axios from 'axios';
import { apiBaseUrl } from './config/globalVariables';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { useState, useMemo } from 'react';
import { Theme, ThemeContext, ThemeList, DefaultTheme } from './contexts/theme';
import {
	UiConfigContext,
	UiConfig,
	DefaultUiConfig,
} from './contexts/uiConfig';

import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';

import './assets/css/materialdesignicons.min.css';
import './assets/css/animate.min.css';
import './assets/fonts/DINRoundPro.css';
import config from 'devextreme/core/config';
import { EditorStyle } from 'devextreme/common';
import dxTextBox from 'devextreme/ui/text_box';
import dxDateBox from 'devextreme/ui/date_box';
import dxNumberBox from 'devextreme/ui/number_box';
import dxSelectBox from 'devextreme/ui/select_box';
import dxTagBox from 'devextreme/ui/tag_box';
import dxLookup from 'devextreme/ui/lookup';
import dxAutocomplete from 'devextreme/ui/autocomplete';
import dxTextArea from 'devextreme/ui/text_area';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import dxColorBox from 'devextreme/ui/color_box';
import dxHtmlEditor from 'devextreme/ui/html_editor';

function App() {
	const { user, loading } = useAuth();

	config({
		licenseKey:
			'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjVjZjgwNGYzLWVhYzAtNDI0NS1iYzY0LTU1ODFhMzY1NjU2ZCIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.fb8ZYB/J+uUIdUJiiKh7auAxtPFw5Xs+0KpNAd8FGcPpcT+K50tWGB/Km0m8c++C3T5qkARIRz4el664iIaQz8i9HqzXC6eaz5sQUjutOM1MZ29TzN/Oy55RORBaW2CYJbpkLA==',
	});
	axios.defaults.baseURL = apiBaseUrl;

	loadMessages(esMessages);
	locale('es-PY');

	if (loading) {
		return <LoadPanel visible={true} />;
	}

	if (user) {
		return <Content />;
	}

	return <UnauthenticatedContent />;
}

export default function Root() {
	const screenSizeClass = useScreenSizeClass();

	console.log('Local Storage Theme: ', localStorage.getItem('theme'));
	console.log('Default Theme: ', DefaultTheme);

	console.log(
		'Local Storage UI Config: ',
		JSON.stringify(localStorage.getItem('ui_config'))
	);
	console.log('Default UI Config: ', JSON.stringify(DefaultUiConfig));

	try {
		if (localStorage.getItem('theme') === null) {
			localStorage.setItem('theme', DefaultTheme);
		}

		if (localStorage.getItem('ui_config') === null) {
			localStorage.setItem('ui_config', JSON.stringify(DefaultUiConfig));
		}
	} catch (e) {
		console.log('Error: ', e);
	}

	let savedTheme = localStorage.getItem('theme') ?? DefaultTheme;
	savedTheme = ThemeList.includes(savedTheme as Theme)
		? (savedTheme as Theme)
		: DefaultTheme;
	const [theme, setTheme] = useState<Theme>(savedTheme as Theme);

	let savedUiConfig = localStorage.getItem('ui_config');
	savedUiConfig =
		savedUiConfig !== null ? JSON.parse(savedUiConfig) : DefaultUiConfig;
	const [uiConfig, setUiConfig] = useState<UiConfig>(
		savedUiConfig as unknown as UiConfig
	);

	config({ editorStylingMode: uiConfig.stylingMode as EditorStyle });

	// Función para configurar globalmente los labels de los editores
	const defaultOptions = {
		labelMode: uiConfig.labelMode,
	};

	const themeValue = useMemo(() => ({ theme, setTheme }), [theme, setTheme]);
	const uiConfigValue = useMemo(
		() => ({ uiConfig, setUiConfig }),
		[uiConfig, setUiConfig]
	);

	dxTextBox.defaultOptions({ options: defaultOptions });
	dxDateBox.defaultOptions({ options: defaultOptions });
	dxNumberBox.defaultOptions({ options: defaultOptions });
	dxSelectBox.defaultOptions({ options: defaultOptions });
	dxTagBox.defaultOptions({ options: defaultOptions });
	dxLookup.defaultOptions({ options: defaultOptions });
	dxAutocomplete.defaultOptions({ options: defaultOptions });
	dxTextArea.defaultOptions({ options: defaultOptions });
	dxDropDownBox.defaultOptions({ options: defaultOptions });
	dxColorBox.defaultOptions({ options: defaultOptions });
	dxHtmlEditor.defaultOptions({ options: defaultOptions });

	return (
		<ThemeContext.Provider value={themeValue}>
			<UiConfigContext.Provider value={uiConfigValue}>
				<Router>
					<AuthProvider>
						<NavigationProvider>
							<div
								id="app"
								className={`app ${screenSizeClass} theme-${theme}`}
							>
								<App />
							</div>
						</NavigationProvider>
					</AuthProvider>
				</Router>
			</UiConfigContext.Provider>
		</ThemeContext.Provider>
	);
}
