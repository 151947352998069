import React, { useContext } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/UserPanel';
import './Header.scss';
import { Template } from 'devextreme-react/core/template';
import type { HeaderProps as OriginalHeaderProps } from '../../types';
import logoAppLight from '../../assets/img/logo-app-light.svg';
import logoAppDark from '../../assets/img/logo-app-dark.svg';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../contexts/theme';

type ReadonlyHeaderProps = Readonly<OriginalHeaderProps>;

export default function Header({
	menuToggleEnabled,
	title,
	toggleMenu,
}: ReadonlyHeaderProps) {
	const { theme } = useContext(ThemeContext);

	return (
		<header className={'header-component'}>
			<Toolbar
				className={
					'header-toolbar animate__animated animate__slideInDown'
				}
			>
				<Item
					visible={menuToggleEnabled}
					location={'before'}
					widget={'dxButton'}
					cssClass={
						'menu-button animate__animated animate__slideInRight animate__delay-1s'
					}
				>
					<Button
						icon="menu"
						stylingMode="text"
						onClick={toggleMenu}
					/>
				</Item>
				<Item
					location={'before'}
					cssClass={
						'header-logo animate__animated animate__slideInRight animate__delay-1s'
					}
				>
					{
						<Link
							to="/"
							className="animate__animated animate__flipInY"
						>
							<img
								src={
									theme === 'light'
										? logoAppLight
										: logoAppDark
								}
								alt="System Logo"
								data-theme={theme}
							/>{' '}
						</Link>
					}
				</Item>
				<Item
					location={'after'}
					locateInMenu={'auto'}
					menuItemTemplate={'userPanelTemplate'}
				>
					<Button
						className={
							'user-button authorization animate__animated animate__slideInLeft animate__delay-1s'
						}
						width={210}
						height={'100%'}
						stylingMode={'text'}
					>
						<UserPanel menuMode={'context'} />
					</Button>
				</Item>
				<Template name={'userPanelTemplate'}>
					<UserPanel menuMode={'list'} />
				</Template>
			</Toolbar>
		</header>
	);
}
