import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { DataGridTypes } from 'devextreme-react/data-grid';
import 'react-json-view-lite/dist/index.css';

import ExampleTab from './example-tab';
import SchemaTab from './schema-tab';

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
	return (
		<TabPanel>
			<Item
				title="Ejemplo"
				render={() => <ExampleTab jsonData={props.data.key.example} />}
			/>
			<Item
				title="Schema"
				render={() => <SchemaTab jsonData={props.data.key.schema} />}
			/>
		</TabPanel>
	);
};

export default MasterDetailView;
