import React from 'react';
import './apiKeys.scss';
import { DataGridApiKeys } from './dataGridApiKeys';
import { Button } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
import { getUserPermissions } from '../../config/actions';
import { useAuth } from '../../contexts/auth';

export default function ApiKeys() {
	const navigate = useNavigate();
    const { user } = useAuth();
	const userPermissions = getUserPermissions(user);
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>API Keys</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<div
						style={{ display: 'flex', justifyContent: 'flex-end' }}
					>
						<Button
							className="finnekta-datagrid-button-add-item"
							onClick={() => navigate('/apikeys/new')}
							disabled={!userPermissions.canCreateApiKey}
						>
							<span className="mdi mdi-key"></span>{/*
							*/}CREAR NUEVO API KEY
						</Button>
					</div>
					<DataGridApiKeys />
				</div>
			</div>
		</React.Fragment>
	);
}
