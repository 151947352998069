import axios from 'axios';
import { ApiKeysPayload } from '../types/apiKeysResponse';
import { getRealmHeaders, getUserHeaders } from './config';

export async function createApiKey(payload: ApiKeysPayload) {
	try {
		const response = await axios.post('/apiKeys', payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return {
			success: true,
			messagge: 'El Api Key fue creado con éxito',
			response: response.data,
		};
	} catch {
		return {
			success: false,
			message: 'El Api Key no pudo ser creado por que pasaron cosas.',
		};
	}
}

export async function editApiKey(payload: ApiKeysPayload, id: string) {
	try {
		const response = await axios.put(`/apiKeys/${id}`, payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return {
			success: true,
			message: 'El Api Key fue editado con éxito',
			response: response.data,
		};
	} catch {
		return {
			success: false,
			message: 'El Api Key no pudo ser editado por que pasaron cosas.',
		};
	}
}
