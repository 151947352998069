// ActionCellRenderer.tsx
import React, { useContext } from 'react';
import { Button } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../utils/showNotification';
import { changeStatusUser } from '../../api/users';
import { UiConfigContext } from '../../contexts/uiConfig';

interface ActionCellRendererProps {
	data: any;
	userPermissions: any;
	showConfirmation: (action: () => Promise<void>, status: string) => void;
}
export const ActionCellRenderer: React.FC<ActionCellRendererProps> = ({
	data,
	userPermissions,
	showConfirmation,
}) => {
	const { uiConfig } = useContext(UiConfigContext);
	const navigate = useNavigate();
	const isActive = data.status === 'ACTIVE';
	const handleStatusChange = async () => {
		const newStatus = isActive ? 'INACTIVE' : 'ACTIVE';
		const result = await changeStatusUser(data.userValue, newStatus);

		if (result.success) {
			showNotification({
				message: result.message,
				type: 'success',
				displayTime: 12000,
				position: uiConfig.notificationsPosition,
			});
		} else {
			showNotification({
				message: result.message,
				type: 'error',
				displayTime: 12000,
				position: uiConfig.notificationsPosition,
			});
		}
	};
	return (
		<div className={'button-action-container'}>
			<Button
				stylingMode={'outlined'}
				onClick={() =>
					showConfirmation(handleStatusChange, data.status)
				}
				className={
					isActive
						? 'finnekta-datagrid-button-delete-action'
						: 'finnekta-button-action'
				}
				disabled={!userPermissions.canEnableUser}
			>
				<span
					className={`mdi mdi-lock-${
						isActive ? 'outline' : 'open-outline'
					}`}
				></span>
				{isActive ? 'Inactivar' : 'Activar'}
			</Button>
			<Button
				stylingMode={'outlined'}
				onClick={() =>
					navigate('/users/edit', { state: { userData: data } })
				}
				className="finnekta-button-action"
				disabled={!userPermissions.canEditUser}
			>
				<span className="mdi mdi-pencil-outline"></span>
				<span>Editar</span>
			</Button>
		</div>
	);
};
