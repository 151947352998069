import React from 'react';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import { CopyClipboard } from '../../../utils/clipboard';

const ExampleTab = ({ jsonData }: any) => {
	return (
		<React.Fragment>
			<button
  				title="Copiar"
  				onClick={() => CopyClipboard(jsonData)}
  				className="btn-copy-json dx-icon mdi mdi-content-copy"
			>
			</button>
			<JsonView
				data={jsonData}
				style={defaultStyles}
				shouldExpandNode={allExpanded}
			/>
		</React.Fragment>
	);
};

export default ExampleTab;
